import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontBold } from "@fdr/static-ui/Typography";
import { generateTransition, easeInOut } from "@fdr/static-ui/Transitions";

export const TabGroupWrapper = styled.ul`
  list-style: none;
  display: flex;
  ${(props) =>
    props.isSubTab
      ? css`
          height: 52px;
          padding: 0 0 0 16px;

          & > li:last-child {
            padding-right: 16px;
          }
        `
      : css`
          height: 44px;
        `}
  width: 100%;
  flex: 1;
  overflow: auto;
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.hasSubTabs &&
    css`
      box-shadow: inset 0 -1px 0 0 ${buildColor("grey", "300")};
    `}
  ${({ isBigTab }) =>
    isBigTab &&
    css`
      box-shadow: inset 0 -1px 0 ${buildColor("grey", "L2")};
      height: 44px;

      & > li {
        display: flex;
        align-items: center;
      }
    `}
`;

export const TabColumn = styled.li`
  flex: ${(props) => (props.stretch ? 1 : 0)};

  button {
    transition: ${generateTransition(easeInOut, "color")};
  }

  ${(props) =>
    props.isSubTab &&
    css`
      align-self: center;

      &:not(:last-child) {
        margin-right: 16px;
      }

      & > button {
        padding: 5px 8px;
        border-radius: 100px;
        height: 28px;
        border: none;
      }
    `}

  ${(props) =>
    props.isSticky &&
    props.hasBottomBorder &&
    css`
      &:not(:last-child) {
        margin-right: 8px;
      }
    `}      

  ${(props) =>
    props.isSubTab &&
    props.isActive &&
    css`
      & > button {
        background: ${buildColor("blue", "D1")};
        color: ${buildColor("white", "100")};

        &:hover {
          background: ${buildColor("blue", "D1")};
          color: ${buildColor("white", "100")};
        }
      }
    `}

  ${(props) =>
    props.isActive &&
    !props.isSubTab &&
    css`
      box-shadow: inset 0 -2px 0 ${buildColor("blue")};

      & > button {
        font-family: ${fontBold};
        font-weight: 500;
      }
    `}

  ${(props) =>
    !props.isSubTab &&
    css`
      > button {
        position: relative;
        display: flex;

        &::after {
          ${(props.displayZero || (props.counter && props.counter > 0)) &&
          css`
            content: "${props.counter.toString()}";
            ${props.displayZero && props.counter === 0
              ? css`
                  background-color: ${buildColor("blue", "900")};
                  color: ${buildColor("grey", "900")};
                  opacity: 0.5;
                `
              : css`
                  background-color: ${buildColor("red")};
                  color: ${buildColor("white")};
                `};
          `};
          border-radius: 50%;
          margin-left: 4px;
          width: 17px;
          height: 17px;
          font-size: 10px;
          font-family: ${fontBold};
          font-weight: 700;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    `}
`;

import styled, { css } from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { Link } from "react-router-dom";
import { Theme } from "@tvg/design-system";

export const SectionContent = styled.section<{
  hasHeader: boolean;
  noTopPadding: boolean;
}>`
  ${({ hasHeader, noTopPadding }) =>
    !noTopPadding &&
    !hasHeader &&
    css`
      padding-top: 8px;
    `}
`;

export const SectionWrapper = styled.section<{
  isNightMode?: boolean;
  withoutBackground?: boolean;
  isVisible?: boolean;
}>`
  width: 100%;
  margin-bottom: 8px;
  background-color: ${({ isNightMode, withoutBackground }) =>
    !withoutBackground && isNightMode
      ? buildColor("navy", "default")
      : buildColor("white", "100")};
  ${({ isNightMode }) =>
    !isNightMode &&
    css`
      border-top: 1px solid ${buildColor("grey", "L2")};
      border-bottom: 1px solid ${buildColor("grey", "L2")};
    `}
  ${({ isVisible }) =>
    !isVisible &&
    css`
      margin-bottom: 0;
      height: 0;
      visibility: hidden;
    `}
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.space["space-5"]};
  min-height: 48px;
`;

export const HeaderLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  text-decoration: none;

  & > p {
    color: ${({ theme }: { theme: Theme }) => theme.colors.blue_accent["600"]};
  }
`;

import styled, { css, keyframes } from "styled-components";

const rotateAnimation = keyframes`
  0% {
    transform: rotateY(0);
  }
  100% {
    transform: rotateY(180deg);
  }
`;

export const Container = styled.div`
  width: 14px;
  height: 16px;
  background-color: transparent;
  perspective: 100px;
`;

export const Wrapper = styled.div<{
  isYellow: boolean;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;

  ${({ isYellow }) =>
    isYellow &&
    css`
      animation: ${rotateAnimation} 0.8s ease-in-out 0.4s forwards;
    `}
`;

export const Content = styled.div<{
  isYellow?: boolean;
  isBack?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--fd-radii-border-radius-010, 2px);
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: ${({ isYellow }) =>
    isYellow
      ? "var(--fd-colors-system-alert-background-default)"
      : "var(--fd-colors-background-base)"};

  ${({ isBack }) =>
    isBack &&
    css`
      transform: rotateY(180deg);
    `}
`;

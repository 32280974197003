import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding: ${({ theme }) => theme.space["space-5"]};

  & > span {
    margin-right: ${({ theme }) => theme.space["space-3"]};
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

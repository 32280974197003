import React, { PureComponent } from "react";

import { noop } from "lodash";
import TabAtom from "./styled-components";

export default class Tab extends PureComponent {
  static defaultProps = {
    title: "",
    subTitle: "",
    isSubtitleHighlighted: false,
    counter: 0,
    displayZero: false,
    isActive: false,
    isDisabled: false,
    onClick: noop,
    qaLabel: "tab",
    type: "tab",
    tag: { isVisible: false, text: "" }
  };

  render() {
    return (
      <TabAtom
        counter={this.props.counter}
        isActive={this.props.isActive}
        displayZero={this.props.displayZero}
        onClick={
          this.props.isDisabled
            ? noop
            : () => this.props.onClick(this.props.title)
        }
        isDisabled={this.props.isDisabled}
        qaLabel={this.props.qaLabel}
        type={this.props.type}
        text={this.props.title}
        tag={this.props.tag}
      >
        {this.props.subTitle ? this.props.subTitle : undefined}
      </TabAtom>
    );
  }
}

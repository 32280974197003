import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontBold } from "@fdr/static-ui/Typography";

export const ListWrapper = styled.ul`
  background-color: ${buildColor("white")};
`;

export const ListContainer = styled.div`
  background-color: ${buildColor("grey", "L4")};
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 20px;

  h1 {
    font-family: ${fontBold};
  }
`;

export const ListItem = styled.li`
  width: 100%;
  background-color: ${buildColor("white")};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid ${buildColor("grey", "L4")};
`;

export const ListItemContent = styled.div`
  margin: 0;
  font-size: 14px;
  color: ${buildColor("grey", "D4")};
`;

export const ListItemWrapper = styled.button`
  display: flex;
  flex-direction: row;
  text-align: left;
  flex: 1;
  align-items: center;
  outline: none;
  background: none;
`;

export const IconWrapper = styled.div`
  width: 24px;
  display: flex;
  align-items: center;
`;

export const DescriptionWrapper = styled.div`
  border-left: 1px solid ${buildColor("grey", "L2")};
  padding-left: 16px;
  margin-top: 16px;
`;

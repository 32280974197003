import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
  fill: var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05));
`;

const ContainerSVG = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-4);
`;

export default function BetTypesLoading() {
  return (
    <ContainerSVG>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="1376"
        viewBox="0 0 651 1376"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="651" height="64" fill="white" />
        <rect width="635" height="64" fill="white" />
        <rect
          x="16"
          y="23"
          width="200"
          height="18"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="651"
          height="32"
          transform="translate(0 64)"
          fill="#F7FBFF"
        />
        <rect
          x="16"
          y="72"
          width="200"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-3-inside-1_104_56481" fill="white">
          <path d="M0 96H651V248H0V96Z" />
        </mask>
        <path d="M0 96H651V248H0V96Z" fill="white" />
        <path
          d="M651 247H0V249H651V247Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-3-inside-1_104_56481)"
        />
        <rect
          x="16"
          y="112"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="112"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="136"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="112"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="112"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="112"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="112"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="112"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="136"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="168"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="192"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="216"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-17-inside-2_104_56481" fill="white">
          <path d="M0 248H651V400H0V248Z" />
        </mask>
        <path d="M0 248H651V400H0V248Z" fill="white" />
        <path
          d="M651 399H0V401H651V399Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-17-inside-2_104_56481)"
        />
        <rect
          x="16"
          y="264"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="264"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="288"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="264"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="264"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="264"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="264"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="264"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="288"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="320"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="344"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="368"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-31-inside-3_104_56481" fill="white">
          <path d="M0 400H651V552H0V400Z" />
        </mask>
        <path d="M0 400H651V552H0V400Z" fill="white" />
        <path
          d="M651 551H0V553H651V551Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-31-inside-3_104_56481)"
        />
        <rect
          x="16"
          y="416"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="416"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="440"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="416"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="416"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="416"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="416"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="416"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="440"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="472"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="496"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="520"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="651"
          height="32"
          transform="translate(0 552)"
          fill="#F7FBFF"
        />
        <rect
          x="16"
          y="560"
          width="200"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-46-inside-4_104_56481" fill="white">
          <path d="M0 584H651V736H0V584Z" />
        </mask>
        <path d="M0 584H651V736H0V584Z" fill="white" />
        <path
          d="M651 735H0V737H651V735Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-46-inside-4_104_56481)"
        />
        <rect
          x="16"
          y="600"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="600"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="624"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="600"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="600"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="600"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="600"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="600"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="624"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="656"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="680"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="704"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-60-inside-5_104_56481" fill="white">
          <path d="M0 736H651V888H0V736Z" />
        </mask>
        <path d="M0 736H651V888H0V736Z" fill="white" />
        <path
          d="M651 887H0V889H651V887Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-60-inside-5_104_56481)"
        />
        <rect
          x="16"
          y="752"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="752"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="776"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="752"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="752"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="752"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="752"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="752"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="776"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="808"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="832"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="856"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-74-inside-6_104_56481" fill="white">
          <path d="M0 888H651V1040H0V888Z" />
        </mask>
        <path d="M0 888H651V1040H0V888Z" fill="white" />
        <path
          d="M651 1039H0V1041H651V1039Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-74-inside-6_104_56481)"
        />
        <rect
          x="16"
          y="904"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="904"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="928"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="904"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="904"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="904"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="904"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="904"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="928"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="960"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="984"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1008"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          width="651"
          height="32"
          transform="translate(0 1040)"
          fill="#F7FBFF"
        />
        <rect
          x="16"
          y="1048"
          width="200"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-89-inside-7_104_56481" fill="white">
          <path d="M0 1072H651V1224H0V1072Z" />
        </mask>
        <path d="M0 1072H651V1224H0V1072Z" fill="white" />
        <path
          d="M651 1223H0V1225H651V1223Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-89-inside-7_104_56481)"
        />
        <rect
          x="16"
          y="1088"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="1088"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="1112"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="1088"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="1088"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="1088"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="1088"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="1088"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="1112"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1144"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1168"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1192"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <mask id="path-103-inside-8_104_56481" fill="white">
          <path d="M0 1224H651V1376H0V1224Z" />
        </mask>
        <path d="M0 1224H651V1376H0V1224Z" fill="white" />
        <path
          d="M651 1375H0V1377H651V1375Z"
          fill="var(--fd-colors-border-subtle, #C6D3E1)"
          mask="url(#path-103-inside-8_104_56481)"
        />
        <rect
          x="16"
          y="1240"
          width="120"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="469"
          y="1240"
          width="56"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="461"
          y="1264"
          width="64"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="557"
          y="1240"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="573"
          y="1240"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="589"
          y="1240"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="605"
          y="1240"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="621"
          y="1240"
          width="14"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="541"
          y="1264"
          width="94"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1296"
          width="420.184"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1320"
          width="540.24"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
        <rect
          x="16"
          y="1344"
          width="484.562"
          height="16"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
      </SVG>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="808"
        viewBox="0 0 651 808"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_104_56484)">
          <rect width="651" height="808" rx="4" fill="white" />
          <rect width="651" height="64" fill="white" />
          <rect width="635" height="64" fill="white" />
          <rect
            x="16"
            y="23"
            width="200"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="76"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="100"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="124"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="156"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="180"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="204"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="236"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="260"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="284"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="324"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="348"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="372"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="404"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="428"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="452"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="484"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="508"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="532"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="572"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="596"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="620"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="652"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="676"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="700"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="732"
            width="420.184"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="756"
            width="540.24"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="780"
            width="484.562"
            height="16"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
        </g>
        <defs>
          <clipPath id="clip0_104_56484">
            <rect width="651" height="808" rx="4" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </ContainerSVG>
  );
}

import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const Title = styled.div`
  width: 100%;
  min-height: 84px;
  padding: var(--fd-space-space-6) var(--fd-space-space-4);
  border-bottom: 1px solid var(--fd-colors-border-default);
  background: var(--fd-colors-background-surface);
`;

export const Columns = styled.div<{
  isArticle: boolean;
  hasMobileBottomSpace: boolean;
}>`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-4);
  background: var(--fd-colors-background-base);

  @media screen and ${breakpoints.tablet.min.sm} {
    padding: var(--fd-space-space-4);
    flex-direction: row;
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    gap: var(--fd-space-space-3);
    padding-top: var(--fd-space-space-3);
    ${({ isArticle }) =>
      isArticle &&
      css`
        padding: 0;
      `};
    ${({ hasMobileBottomSpace }) =>
      hasMobileBottomSpace &&
      css`
        /* Add extra padding at the bottom on the mobile Pages that use Tabs to ensure the last tab functions correctly */
        padding-bottom: 150px;
      `}
  }
`;

const gap = 16;
const sideWidth = 325;

export const MainContent = styled.div<{ isFullHeight?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);

  @media screen and ${breakpoints.tablet.min.sm} {
    width: calc(100% - ${sideWidth + gap}px);

    &:only-child {
      width: 100%;
      max-width: 651px;
      margin: 0 auto;
    }
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    ${({ isFullHeight }) =>
      isFullHeight &&
      css`
        flex-grow: 1;

        section:only-child {
          flex-grow: 1;
        }
      `}
  }
`;

export const SideContent = styled.div`
  @media screen and ${breakpoints.tablet.min.sm} {
    width: ${sideWidth}px;
  }
`;

export const LatestVideos = styled.section`
  background: var(--fd-colors-background-surface);
  border-bottom: 1px solid var(--fd-colors-border-subtle);
`;

export const MobileHeader = styled.div`
  background: var(--fd-colors-background-surface);
  position: sticky;
  top: 0;
  z-index: 50;
  will-change: transform;
  transform: translate3d(0, 0, 0);
`;

import React from "react";
import styled, { keyframes } from "styled-components";
import { breakpoints } from "@tvg/design-system";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  background: var(--fd-colors-background-surface);
  height: 301px;

  @media screen and ${breakpoints.tablet.min.sm} {
    border-radius: var(--fd-radii-border-radius-020);
  }
`;

export default function FAQLoading() {
  return (
    <Container>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="301"
        viewBox="0 0 325 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_104_53677)">
          <mask id="path-2-inside-1_104_53677" fill="white">
            <path d="M0 0.5H325V50.5H0V0.5Z" />
          </mask>
          <path d="M0 0.5H325V50.5H0V0.5Z" fill="white" />
          <path
            d="M325 49.5H0V51.5H325V49.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-2-inside-1_104_53677)"
          />
          <rect
            x="16"
            y="16.5"
            width="200"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            width="325"
            height="250"
            transform="translate(0 50.5)"
            fill="white"
          />
          <mask id="path-5-inside-2_104_53677" fill="white">
            <path d="M0 50.5H325V100.5H0V50.5Z" />
          </mask>
          <path d="M0 50.5H325V100.5H0V50.5Z" fill="white" />
          <path
            d="M325 99.5H0V101.5H325V99.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-5-inside-2_104_53677)"
          />
          <rect
            x="16"
            y="66.5"
            width="182.517"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="291"
            y="66.5"
            width="18"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <mask id="path-9-inside-3_104_53677" fill="white">
            <path d="M0 100.5H325V150.5H0V100.5Z" />
          </mask>
          <path d="M0 100.5H325V150.5H0V100.5Z" fill="white" />
          <path
            d="M325 149.5H0V151.5H325V149.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-9-inside-3_104_53677)"
          />
          <rect
            x="16"
            y="116.5"
            width="206.652"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="291"
            y="116.5"
            width="18"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <mask id="path-13-inside-4_104_53677" fill="white">
            <path d="M0 150.5H325V200.5H0V150.5Z" />
          </mask>
          <path d="M0 150.5H325V200.5H0V150.5Z" fill="white" />
          <path
            d="M325 199.5H0V201.5H325V199.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-13-inside-4_104_53677)"
          />
          <rect
            x="16"
            y="166.5"
            width="191.745"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="291"
            y="166.5"
            width="18"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <mask id="path-17-inside-5_104_53677" fill="white">
            <path d="M0 200.5H325V250.5H0V200.5Z" />
          </mask>
          <path d="M0 200.5H325V250.5H0V200.5Z" fill="white" />
          <path
            d="M325 249.5H0V251.5H325V249.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-17-inside-5_104_53677)"
          />
          <rect
            x="16"
            y="216.5"
            width="218.011"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="291"
            y="216.5"
            width="18"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <mask id="path-21-inside-6_104_53677" fill="white">
            <path d="M0 250.5H325V300.5H0V250.5Z" />
          </mask>
          <path d="M0 250.5H325V300.5H0V250.5Z" fill="white" />
          <path
            d="M325 299.5H0V301.5H325V299.5Z"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            mask="url(#path-21-inside-6_104_53677)"
          />
          <rect
            x="16"
            y="266.5"
            width="182.517"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="291"
            y="266.5"
            width="18"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
        </g>
        <defs>
          <clipPath id="clip0_104_53677">
            <rect y="0.5" width="325" height="300" rx="4" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
}

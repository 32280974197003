import { URPEducationHubMessages } from "../types";

export const urpEducationHubDefaultMessages: URPEducationHubMessages = {
  homeMenuItem: {
    name: "Horse Racing 101",
    description: "Get to know the basics",
    route: "/horse-racing-101",
    icon: "infoOutline",
    subQaLabel: "HorseRacing101_ListItem-FindOutMoreBtn"
  },
  playlistDefaultSettings: {
    playlistUrl: "",
    playerScript: "",
    licenseKey: ""
  }
};

import React, { PureComponent } from "react";
import { bindAll, get, noop, throttle } from "lodash";

import { ScrollWrapperContainer } from "./styled-components";

export default class ScrollWrapper extends PureComponent {
  static defaultProps = {
    showFaders: true,
    biggerFadding: false,
    centerFaders: true,
    fadeFrom: "rgba(255, 255, 255, 0)",
    fadeTo: "rgba(255, 255, 255, 1)",
    qaLabel: "scrollWrapper",
    isFullWidth: false,
    scrollEventCallBack: noop,
    hasBottomShadow: false,
    isForcingMinWidth: true,
    checkScrollOverflow: noop,
    betType: "",
    isSticky: false,
    hasBottomBorder: false,
    noTopSpace: false
  };

  callScrollCallBack = throttle(
    () => {
      // execute callback on scroll
      this.props.scrollEventCallBack(
        this.scrollPosition - this.scrollableElement.scrollLeft
      );
      this.scrollPosition = this.scrollableElement.scrollLeft;
    },
    500,
    { trailing: true, leading: false }
  );

  ref;

  scrollPosition;

  scrollableElement;

  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      hasContentLeft: false,
      hasContentRight: false,
      isListening: false,
      initialWidth: 0
    };

    this.scrollPosition = 0;

    bindAll(this, [
      "verifyContentOverflow",
      "checkScrollableElement",
      "getScrollWrapperInitialWidth"
    ]);
  }

  componentDidMount() {
    // FIX: fixes problem with modal opening on my bets and probables - if we dont have this setTimeout, opening my bets, the modal doesn't show any transition
    // it seems that it is too much js processing and the transition group can't handle it so we need to take this execution off the call stack
    setTimeout(() => this.checkScrollableElement(), 0);
    this.getScrollWrapperInitialWidth();
  }

  componentDidUpdate(prevProps) {
    const previousChildrenKey = get(prevProps, "children.key");
    const currentChildrenKey = get(this.props, "children.key");

    if (previousChildrenKey !== currentChildrenKey) {
      // means content has changed, re-check for scrollable element inside children
      this.checkScrollableElement();
    }

    if (prevProps.betType !== this.props.betType) {
      this.props.checkScrollOverflow(false);
      setTimeout(() => this.getScrollWrapperInitialWidth(), 0);
    }
  }

  componentWillUnmount() {
    if (get(this, "scrollableElement.removeEventListener")) {
      this.scrollableElement.removeEventListener(
        "scroll",
        this.verifyContentOverflow
      );
    }
  }

  getScrollWrapperInitialWidth() {
    this.scrollableElement = get(this.ref, "firstChild");

    this.setState({
      initialWidth: this.scrollableElement.clientWidth
    });
  }

  checkScrollableElement() {
    this.scrollableElement = get(this.ref, "firstChild");

    this.props.checkScrollOverflow(
      this.scrollableElement?.firstChild &&
        this.scrollableElement.firstChild.clientWidth > this.state.initialWidth
    );

    if (
      this.scrollableElement &&
      this.scrollableElement.scrollWidth > this.scrollableElement.clientWidth
    ) {
      this.verifyContentOverflow();

      if (!this.state.isListening) {
        this.scrollableElement.addEventListener(
          "scroll",
          this.verifyContentOverflow
        );
        this.setState({ isListening: true });
      }
    }
  }

  verifyContentOverflow() {
    const hasContentLeft = this.scrollableElement.scrollLeft > 0;

    // the 2 is in place to account for extremely small mathematical variants in pixel calculations
    const marginError = 2;

    const hasContentRight =
      this.scrollableElement.scrollWidth -
        this.scrollableElement.clientWidth -
        this.scrollableElement.scrollLeft >
      marginError;

    this.setState({ hasContentLeft, hasContentRight });

    this.callScrollCallBack();
  }

  render() {
    return (
      <ScrollWrapperContainer
        isForcingMinWidth={this.props.isForcingMinWidth}
        hasBottomShadow={this.props.hasBottomShadow}
        data-qa-label={this.props.qaLabel}
        fadeFrom={this.props.fadeFrom}
        fadeTo={this.props.fadeTo}
        hasContentLeft={this.state.hasContentLeft}
        hasContentRight={this.state.hasContentRight}
        showFaders={this.props.showFaders}
        centerFaders={this.props.centerFaders}
        ref={(comp) => {
          this.ref = comp;
        }}
        isFullWidth={this.props.isFullWidth}
        biggerFadding={this.props.biggerFadding}
        isSticky={this.props.isSticky}
        hasBottomBorder={this.props.hasBottomBorder}
        noTopSpace={this.props.noTopSpace}
        zIndex={this.props.zIndex}
      >
        {this.props.children}
      </ScrollWrapperContainer>
    );
  }
}

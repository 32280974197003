import styled, { css } from "styled-components";

export const RichTextStyled = styled.div<{
  hasPadding?: boolean;
  hasSectionsMargin?: boolean;
  boldTextMedium?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-2, 8px);
  line-height: var(--fd-font-sizes-font-size-060, 18px);
  font-size: var(--fd-font-sizes-font-size-040, 14px);
  color: var(--fd-colors-content-default);
  padding: ${({ hasPadding }) =>
    hasPadding ? "var(--fd-space-space-4, 16px)" : 0};

  p:first-of-type {
    margin-bottom: var(--fd-space-space-3, 16px);
  }

  b {
    font-weight: var(--fd-font-weights-font-weight-050, 700);

    ${({ boldTextMedium }) =>
      boldTextMedium &&
      css`
        font-size: var(--fd-font-sizes-font-size-050, 16px);
      `};
  }

  h3 {
    padding-bottom: var(--fd-space-space-3, 12px);
  }

  ol,
  ul {
    list-style: decimal;
    padding-left: var(--fd-space-space-3, 12px);

    li {
      padding-left: var(--fd-space-space-1, 4px);
    }
  }

  ${({ hasSectionsMargin }) =>
    hasSectionsMargin &&
    css`
      p:has(b) {
        margin-bottom: var(--fd-space-space-1, 4px);

        &:not(:first-child) {
          margin-top: var(--fd-space-space-5, 20px);
        }
      }
    `};
`;

import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  min-width: 162px;
  background-color: var(--fd-colors-background-surface);
`;

export default function ArticleCardLoading() {
  return (
    <Container>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="142"
        viewBox="0 0 162 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_155040)">
          <rect width="162" height="142" rx="4" fill="white" />
          <rect width="162" height="94" fill="white" />
          <rect
            x="16"
            y="16"
            width="56"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="38"
            width="97.6484"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <rect
            x="16"
            y="60"
            width="119.616"
            height="18"
            rx="2"
            fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
          />
          <g filter="url(#filter0_i_1_155040)">
            <rect
              width="162"
              height="34"
              transform="translate(0 108)"
              fill="var(--fd-colors-background-base, #eaf0f6)"
            />
            <g clipPath="url(#clip1_1_155040)">
              <rect
                x="12"
                y="116"
                width="78.6094"
                height="18"
                rx="2"
                fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
              />
              <rect
                x="128.613"
                y="116"
                width="21.3867"
                height="18"
                rx="2"
                fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
              />
            </g>
          </g>
        </g>
        <rect
          x="0.5"
          y="0.5"
          width="161"
          height="141"
          rx="3.5"
          stroke="var(--fd-colors-border-subtle, #C6D3E1)"
        />
        <defs>
          <filter
            id="filter0_i_1_155040"
            x="0"
            y="108"
            width="162"
            height="34"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.811765 0 0 0 0 0.839216 0 0 0 0 0.858824 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="shape"
              result="effect1_innerShadow_1_155040"
            />
          </filter>
          <clipPath id="clip0_1_155040">
            <rect width="162" height="142" rx="4" fill="white" />
          </clipPath>
          <clipPath id="clip1_1_155040">
            <rect
              width="138"
              height="17"
              fill="white"
              transform="translate(12 116.5)"
            />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
}

import styled from "styled-components";
import { TagVariant } from "./types";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--fd-space-space-4);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--fd-colors-border-subtle);
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  gap: var(--fd-space-space-4);
  margin-bottom: var(--fd-space-space-4);
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PayoutItems = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const tagColors = {
  positive: {
    backgroundColor: "var(--fd-colors-system-positive-background-default)",
    color: "var(--fd-colors-content-on-dark)"
  },
  warning: {
    backgroundColor: "var(--fd-colors-system-warning-background-default)",
    color: "var(--fd-colors-content-default)"
  },
  danger: {
    backgroundColor: "var(--fd-colors-system-important-background-default)",
    color: "var(--fd-colors-content-on-dark)"
  }
};

export const TagContainer = styled.div<{ variant: TagVariant }>`
  display: flex;
  align-items: center;
  height: 16px;
  padding: 0 var(--fd-space-space-1);
  border-radius: var(--fd-radii-border-radius-010, 2px);
  ${({ variant }) => tagColors[variant as TagVariant]};
`;

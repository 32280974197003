import React, { useEffect, useRef, useState } from "react";
import ReactJWPlayer from "@jwplayer/jwplayer-react";
import {
  JWPlayerFDRSkin,
  PlayerContainer,
  FloatingPlayer
} from "./styled-components";
import { CaptionOptions, JWPlayerProps, Player, JWPlayerType } from "./types";

// JWPlayer reference: https://docs.jwplayer.com/players/reference/javascript-player-api-introduction

const captionOptions: CaptionOptions = {
  backgroundColor: "#000000",
  backgroundOpacity: 75,
  color: "#ffffff",
  edgeStyle: "none",
  fontFamily: "Arial",
  fontOpacity: 100,
  fontSize: 8,
  windowColor: "#000000",
  windowOpacity: 0
};

const JWPlayer = ({
  src,
  isAutoPlay = false,
  isFloatOnScroll = false,
  renderCaptionsNatively = false,
  allowFullscreen = false,
  height,
  width,
  ...props
}: JWPlayerProps) => {
  const [isVideoFloating, setIsVideoFloating] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const activeJWPlayerRef = useRef<Player>();

  const defaultConfig = {
    skin: { name: "fdr" },
    autostart: isAutoPlay ? "viewable" : false,
    mute: !!isAutoPlay,
    captions: captionOptions,
    allowFullscreen,
    renderCaptionsNatively,
    height,
    width
  };

  const handlePlayerMount = ({ id, player }: JWPlayerType) => {
    activeJWPlayerRef.current = player;
    props.didMountCallback?.({ id, player });
  };

  useEffect(() => {
    let observer: IntersectionObserver | null;
    let player: HTMLElement | null;

    if (isPlayerReady && isFloatOnScroll) {
      player = document.querySelector(`#${activeJWPlayerRef.current?.id}`);

      if (!player) return undefined;

      const parentContainer = player?.closest("[data-jw-player-container]");

      if (!parentContainer) return undefined;

      const options = {
        rootMargin: "0px",
        threshold: 0
      };
      observer = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];
        setIsVideoFloating(!isIntersecting);
      }, options);

      observer.observe(parentContainer);
    }

    return () => {
      if (observer && player) {
        observer?.unobserve(player);
      }
    };
  }, [isPlayerReady]);

  return (
    <>
      <JWPlayerFDRSkin />
      <PlayerContainer data-jw-player-container>
        <FloatingPlayer isVideoFloating={isVideoFloating}>
          <ReactJWPlayer
            config={defaultConfig}
            file={src}
            events={{ ready: () => setIsPlayerReady(true) }}
            didMountCallback={handlePlayerMount}
            {...props}
          />
        </FloatingPlayer>
      </PlayerContainer>
    </>
  );
};

export default JWPlayer;

export type { Player };

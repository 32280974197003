import React, { Component } from "react";
import { get, isEqual } from "lodash";

import Tab from "../../_atoms/Tab";
import ScrollWrapper from "../ScrollWrapper";
import { TabColumn, TabGroupWrapper } from "./styled-components";

export default class TabGroup extends Component {
  static defaultProps = {
    tabs: [],
    stretch: true,
    hasBottomShadow: false,
    isBigTab: false,
    isSticky: false,
    hasBottomBorder: false,
    noTopSpace: false
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.tabs.length !== nextProps.tabs.length ||
      !isEqual(nextProps, this.props)
    );
  }

  render() {
    let hasSubTabs = false;
    let isSubTab = false;

    const Tabs = this.props.tabs.map((tab, index) => {
      if (tab.isActive && get(tab, "hasSubTabs", false)) {
        hasSubTabs = tab.hasSubTabs;
      }
      isSubTab = tab.type === "subTab";

      return (
        <TabColumn
          key={`tab-${index.toString()}`}
          isActive={tab.isActive}
          stretch={this.props.stretch}
          isSubTab={isSubTab}
          counter={tab.counter}
          displayZero={tab.displayZero}
          isSticky={this.props.isSticky}
          hasBottomBorder={this.props.hasBottomBorder}
        >
          <Tab
            title={tab.title}
            subTitle={tab.subTitle}
            isActive={tab.isActive}
            isDisabled={tab.isDisabled}
            onClick={tab.onClick}
            qaLabel={`${tab?.qaLabel ?? "tab"}-${tab.title.toLowerCase()}`}
            type={tab.type}
            tag={tab.tag}
          />
        </TabColumn>
      );
    });

    return (
      <ScrollWrapper
        hasBottomShadow={this.props.hasBottomShadow}
        hasBottomBorder={this.props.hasBottomBorder}
        isSticky={this.props.isSticky}
        noTopSpace={this.props.noTopSpace}
        zIndex={this.props.zIndex}
      >
        <TabGroupWrapper
          data-qa-label="tabGroup"
          hasSubTabs={hasSubTabs}
          isSubTab={isSubTab}
          isBigTab={this.props.isBigTab}
        >
          {Tabs}
        </TabGroupWrapper>
      </ScrollWrapper>
    );
  }
}

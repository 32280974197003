import { useMemo } from "react";
import { useWagerProfile } from "@tvg/custom-hooks";
import { get, orderBy } from "lodash";
import type { TopPool } from "@tvg/ts-types/Pool";
import { useQuery, QueryHookOptions } from "@apollo/client";
import { ApolloOptionsProps, TopPoolsApolloResponse } from "../types";
import apolloOptions from "../graphql/options";
import { GET_TOP_POOLS } from "../graphql/queries";

export const removeFalsePinned = (topPools: TopPool[]) =>
  topPools.map<TopPool>((pool) =>
    get(pool, "race.highlighted.pinnedOrder", 0) === 0
      ? ({
          ...pool,
          race: {
            ...pool.race,
            highlighted: {
              ...pool.race.highlighted,
              pinnedOrder: null as unknown
            }
          }
        } as TopPool)
      : pool
  );

const getPools = (topPools: TopPool[]) =>
  orderBy(
    removeFalsePinned(topPools),
    [(pool) => get(pool, "race.highlighted.pinnedOrder", null), "amount"],
    ["asc", "desc"]
  );

const useTopPools = ({
  shouldUpdate = true,
  ...options
}: {
  shouldUpdate?: boolean;
} & QueryHookOptions) => {
  const wagerProfile = useWagerProfile() as ApolloOptionsProps["wagerProfile"];

  // QUERY
  const { data, loading } = useQuery<TopPoolsApolloResponse>(
    GET_TOP_POOLS,
    apolloOptions({ shouldUpdate, wagerProfile, ...options })
  );

  const topPools: TopPool[] = useMemo<TopPool[]>(
    () => getPools(data?.carryOverPools ?? []),
    [data?.carryOverPools.length]
  );

  return { topPools, loading, hasPools: topPools?.length > 0 };
};

export default useTopPools;

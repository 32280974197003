import React from "react";
import { Device } from "@tvg/ts-types/Device";
import { useNavigate } from "react-router";
import { handleTouchStart } from "@tvg/utils/swipeEventHandlers";
import { useSelector } from "react-redux";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import { getOptedInPromos } from "@urp/store-selectors";
import { Props } from "./types";
import TopPoolsWrapper from "../TopPoolsWrapper";
import { getSortedPools, onPoolClick, onTouchMove } from "./utils";
import { getCapiMessageContent } from "../../redux/selectors";
import LoadingMask from "../LoadingMask";
import useTopPools from "../../hooks/useTopPools";

const TopPools = ({ device, title, qaLabel, ...optionsProps }: Props) => {
  // CUSTOM HOOKS
  const isDesktop = device
    ? device === Device.DESKTOP
    : useMediaQuery(breakpoints.tablet.min.lg);
  const { hasPools, loading, topPools } = useTopPools({
    shouldUpdate: optionsProps.shouldUpdate
  });

  // SELECTORS
  const {
    title: capiTitle,
    highlightsTitle,
    upcomingTitle
  } = useSelector(getCapiMessageContent);
  const optedInPromos = useSelector(getOptedInPromos);

  const navigate = useNavigate();

  if (loading) return <LoadingMask isDesktop={isDesktop} />;
  if (!hasPools) return null;

  return (
    <TopPoolsWrapper
      title={title || capiTitle}
      pools={topPools.sort(getSortedPools)}
      isDesktop={isDesktop}
      onPoolClick={onPoolClick(navigate, optedInPromos)}
      onTouchStart={handleTouchStart}
      onTouchMove={onTouchMove}
      highlightsTitle={highlightsTitle}
      upcomingTitle={upcomingTitle}
      qaLabel={qaLabel}
    />
  );
};

export default TopPools;

import React from "react";
import { noop } from "lodash";

import {
  ColorTokens,
  IconButton,
  Paragraph,
  useColorTokens,
  useTheme
} from "@tvg/design-system";
import type { PreviousWinnersListProps } from "../../types";
import PreviousWinner from "../PreviousWinner";
import {
  WinnersList,
  ListItem,
  LastElement,
  IconContainer
} from "./styled-components";

const PreviousWinnersList = ({
  winners,
  isDesktop,
  isModal,
  hasUniqueWinner = false,
  showSeeAllElement = false,
  onModalOpen = noop,
  onSelection
}: PreviousWinnersListProps) => {
  const theme = useTheme();
  const { content } = useColorTokens() as ColorTokens;
  const Wrapper = isModal ? React.Fragment : WinnersList;

  return (
    <Wrapper
      theme={theme}
      isDesktop={isDesktop}
      isModal={isModal}
      data-qa-label="previousWinners-List"
    >
      <>
        {winners.map((winner, index) => (
          <ListItem
            key={`${winner.currentRace.raceId}-${index.toString()}`}
            theme={theme}
            isDesktop={isDesktop}
            isModal={isModal}
          >
            <PreviousWinner
              winner={winner}
              isDesktop={isDesktop}
              isModal={isModal}
              hasUniqueWinner={hasUniqueWinner}
              onSelection={onSelection}
              qaLabel="previousWinner"
            />
          </ListItem>
        ))}
        {showSeeAllElement && !isDesktop && (
          <LastElement theme={theme}>
            <IconContainer
              theme={theme}
              data-qa-label="previousWinnersLastElement-IconContainer"
              onClick={onModalOpen}
            >
              <IconButton
                size="s"
                variant="tertiary"
                iconName="arrowRight"
                qaLabel="previousWinnersLastElement-SeeAllIconBtn"
                tertiaryColor={content.link}
                onPress={onModalOpen}
              />
            </IconContainer>
            <Paragraph
              qaLabel="previousWinnersLastElement-SeeAll"
              color={content.link}
              fontSize="xs"
              fontFamily="medium"
              onPress={onModalOpen}
            >
              SEE ALL
            </Paragraph>
          </LastElement>
        )}
      </>
    </Wrapper>
  );
};

export default PreviousWinnersList;

import { useEffect } from "react";
import { attempt } from "lodash";
import { SubscribeToMoreOptions } from "@apollo/client/core";
import type { TalentPick } from "@tvg/ts-types/TalentPicks";

import ApolloOptions from "../graphql/options.graph";
import { GET_TALENT_PICKS_SUBSCRIPTION } from "../graphql/queries/talentPicksSubscription";

const useTalentPicksSubscription = (
  subscribeToMore: (options: SubscribeToMoreOptions) => () => void,
  wagerProfile: string | undefined,
  useCache: boolean = false
) => {
  useEffect(() => {
    let unSubscribe: unknown = null;
    attempt(() => {
      if (wagerProfile && !useCache) {
        unSubscribe = subscribeToMore({
          document: GET_TALENT_PICKS_SUBSCRIPTION,
          variables: ApolloOptions.options({ wagerProfile }).variables,
          updateQuery: (previous, { subscriptionData }) => {
            if (
              previous?.talentPicksList?.find(
                (talent: TalentPick) =>
                  subscriptionData?.data?.talentPicksList?.id === talent?.id
              )
            ) {
              return previous;
            }
            const talentPicksList = [
              subscriptionData?.data?.talentPicksList,
              ...previous?.talentPicksList
            ];
            const sorted = talentPicksList.sort(
              (talentA, talentb) => talentA?.race?.mtp - talentb?.race?.mtp
            );
            return {
              talentPicksList: sorted
            };
          }
        });
      }
    });

    return () => {
      if (typeof unSubscribe === "function") {
        unSubscribe();
      }
    };
  }, [wagerProfile, subscribeToMore]);
};

export default useTalentPicksSubscription;

import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontBold, fontCondensed, fontNormal } from "@fdr/static-ui/Typography";

export const BetTypeContainer = styled.li`
  background-color: ${buildColor("white", "100")};
  list-style: none;
  margin-top: 20px;
  padding: 0 12px 20px;
  border-bottom: 1px solid ${buildColor("grey", "300")};
`;

export const BetTitle = styled.span`
  color: ${buildColor("black", "100")};
  font-family: ${fontBold};
  font-size: 14px;
`;

export const BetTypeInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 12px;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 38px;
`;

export const Label = styled.span`
  color: ${buildColor("grey", "800")};
  font-family: ${fontCondensed};
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

export const BetTypeDescription = styled.span`
  color: ${buildColor("grey", "900")};
  font-family: ${fontNormal};
  font-size: 14px;
  line-height: 125%;
`;

export const Payout = styled.div`
  height: 18px;
  width: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isYellow }) =>
    isYellow ? buildColor("yellow", "D1") : buildColor("grey", "L4")};
  margin-right: 2px;

  &::after {
    content: "$";
    font-size: 12px;
    color: ${buildColor("navy", "700")};
  }
`;

export const PayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

import React from "react";
import {
  ColorTokens,
  Header as Title,
  useColorTokens
} from "@tvg/design-system";
import { Wrapper, Header, ListContainer } from "./styled-components";
import DesktopView from "../DesktopView";
import MobileView from "../MobileView";
import { Props } from "./types";
import { getDeviceStyleConfig } from "../../utils";
import { QA_LABEL } from "../../qaLabels";

const TopPoolsWrapper = ({
  pools = [],
  isDesktop,
  title,
  onPoolClick,
  onTouchStart,
  onTouchMove,
  highlightsTitle,
  upcomingTitle,
  qaLabel
}: Props) => {
  const { background, content } = useColorTokens() as ColorTokens;

  const style = getDeviceStyleConfig(isDesktop);

  const sharedProps = {
    style,
    onPoolClick,
    pools
  };

  return (
    <Wrapper
      data-qa-label={qaLabel}
      borderRadius={style.borderRadius}
      backgroundColor={background.surface}
    >
      {isDesktop && (
        <Header
          height={style.header.height}
          data-qa-label={QA_LABEL.header.container}
        >
          <Title
            tag="h1"
            fontSize={style.header.title.fontSize}
            fontWeight="bold"
            color={content.default}
            qaLabel={QA_LABEL.header.title}
            fontFamily="bold"
          >
            {title}
          </Title>
        </Header>
      )}
      <ListContainer
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        flexDirection={style.body.direction}
        gap={style.body.gap}
        padding={style.body.padding}
        data-qa-label={QA_LABEL.body.container}
      >
        {isDesktop ? (
          <DesktopView
            {...sharedProps}
            highlightsTitle={highlightsTitle}
            upcomingTitle={upcomingTitle}
          />
        ) : (
          <MobileView {...sharedProps} />
        )}
      </ListContainer>
    </Wrapper>
  );
};

export default TopPoolsWrapper;

import React from "react";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { Mtp } from "@tvg/design-system/web";
import { MTPContainer } from "./styled-components";

import { Props } from "./types";
import { QA_LABEL } from "../../qaLabels";

const MtpValue = ({ race }: Props) => (
  <MTPContainer>
    <Mtp
      qaLabel={QA_LABEL.body.mtp}
      mtp={race.mtp}
      status={RaceStatusEnum.OPEN}
      postTime={race.postTime}
      whiteSpace="nowrap"
      size="xs"
      paddingRight="space-4"
    />
  </MTPContainer>
);

export default MtpValue;

import styled, { css } from "styled-components";
import { ContainerProps } from "./types";

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px 4px 0 0;
  gap: var(--fd-space-space-base);
  padding: var(--fd-space-space-4);
  ${({ hasDescription }) =>
    hasDescription
      ? css`
          background-color: var(--fd-colors-background-promotional);
        `
      : css`
          background-color: var(--fd-colors-background-surface);
        `}
`;

export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--fd-space-space-4);
  align-items: center;
`;

export interface Description {
  title: string;
  description: string;
  category: string;
  uploadedAt: string;
}

interface Video {
  playerId: string;
  duration: string;
  src: string;
  height: number;
  width: number;
  thumbnail: string;
  playerScript: string;
  licenseKey: string;
  tracks: JwtTrack[];
}

export interface Playlist {
  description: Description;
  video: Video;
}

export interface JwtImage {
  src: string;
  type: string;
  width: number;
}

export interface JwtSource extends JwtTrack {
  type: string;
  width?: number;
  height?: number;
  bitrate?: number;
  filesize?: number;
  framerate?: number;
}

export interface JwtTrack {
  file: string;
  label?: string;
  kind?: string;
}

export interface JwtPlaylistResponse {
  description: string;
  duration: number;
  feedid: string;
  image: string;
  images: JwtImage[];
  link: string;
  mediaid: string;
  pubdate: number;
  sources: JwtSource[];
  title: string;
  tracks: JwtTrack[];
  variations: {};
  category?: string;
  tags?: string;
}

export interface JwtResponse {
  description: string;
  feed_instance_id: string;
  feedid: string;
  kind: string;
  links: { first: string; last: string };
  playlist: JwtPlaylistResponse[];
  title: string;
  raceDate?: string;
  raceId?: string;
}

export interface JWVideoPlaylist {
  title: string;
  raceDate: string;
  raceId: string;
  playlist: Playlist[];
}

export interface PlaylistSettingsMessage {
  playlistUrl: string;
  playerScript: string;
  licenseKey: string;
}

export enum VideoNativeRotation {
  PORTRAIT,
  LANDSCAPE,
  ALL
}

import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
  height: 84px;
`;

export default function PageTitleLoading() {
  return (
    <Container>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="84"
        viewBox="0 0 1024 84"
        fill="none"
        preserveAspectRatio="none"
      >
        <mask id="path-1-inside-1_104_53672" fill="white">
          <path d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H1020C1022.21 0.5 1024 2.29086 1024 4.5V83.5H0V4.5Z" />
        </mask>
        <path
          d="M0 4.5C0 2.29086 1.79086 0.5 4 0.5H1020C1022.21 0.5 1024 2.29086 1024 4.5V83.5H0V4.5Z"
          fill="white"
        />
        <path
          d="M0 0.5H1024H0ZM1024 84.5H0V82.5H1024V84.5ZM0 83.5V0.5V83.5ZM1024 0.5V83.5V0.5Z"
          fill="#C9D1DB"
          mask="url(#path-1-inside-1_104_53672)"
        />
        <rect
          x="10"
          y="24.5"
          width="300"
          height="35"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
      </SVG>
    </Container>
  );
}

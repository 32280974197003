import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontCondensed } from "@fdr/static-ui/Typography";

export const BetCategoryTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  height: 34px;
  background-color: ${buildColor("grey", "L4")};
  font-size: 14px;
  font-family: ${fontCondensed};
`;

import React, { useMemo, useState } from "react";
import get from "lodash/get";
import { useNavigate } from "react-router-dom";
import { Header, PageHeader, AnchorTabs } from "@tvg/design-system/web";
import tvgConf from "@tvg/conf";
import StoryblokClient from "storyblok-js-client";
import { breakpoints, useMediaQuery } from "@tvg/design-system";
import ComplianceBanner from "@urp/compliance-banner";
import {
  PlaylistVideos,
  Articles,
  RacingFAQ,
  HomePageLoading,
  WalkthroughModal,
  SeoHeader
} from "../components";
import useHomeData from "../hooks/useHomeData";
import { getSectionByType } from "../utils/storyblok";
import {
  Container,
  Title,
  Columns,
  MainContent,
  SideContent,
  LatestVideos,
  MobileHeader
} from "./styled-components";

const storyblokToken = tvgConf().config("storyblokToken");
const storyblokClient = new StoryblokClient({
  accessToken: storyblokToken,
  cache: {
    clear: "auto",
    type: "memory"
  }
});

const Homepage = () => {
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const navigate = useNavigate();
  const { homeData, isLoading } = useHomeData();
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const latestVideos = useMemo(
    () => getSectionByType(homeData?.sections, "video_playlist"),
    [homeData]
  );

  const faq = useMemo(
    () => getSectionByType(homeData?.sections, "faq"),
    [homeData]
  );

  const tabs = useMemo(
    () =>
      homeData?.sections?.map((tabItem) => ({
        id: tabItem.tab_name.toLowerCase(),
        label: tabItem.tab_name
      })) || [],
    [homeData]
  );

  if (isLoading) return <HomePageLoading isDesktop={isDesktop} />;

  return (
    <Container data-qa-label="education-hub-container">
      {homeData?.seo_meta_data && (
        <SeoHeader seoMetaData={homeData.seo_meta_data} />
      )}
      {isDesktop ? (
        <Title>
          <Header
            tag="h1"
            fontFamily="bold"
            qaLabel="education-hub-title"
            color="--fd-colors-content-strong"
            fontSize="28px"
            lineHeight="35px"
          >
            {get(homeData, "title", "")}
          </Header>
        </Title>
      ) : (
        <MobileHeader>
          <PageHeader
            qaLabel="education-hub-page-header"
            title={get(homeData, "title", "")}
            showCloseButton
            onClose={() => navigate(-1)}
            py="14px"
          />
          <ComplianceBanner onBannerVisibility={setIsBannerVisible} />
          <AnchorTabs
            sections={tabs}
            offsetMargin={isBannerVisible ? 131 : 97}
            isSticky={false}
          />
        </MobileHeader>
      )}
      {latestVideos && (
        <LatestVideos
          id={latestVideos.tab_name.toLowerCase()}
          data-qa-label="section-latest-videos"
        >
          <PlaylistVideos
            title={latestVideos.title}
            playlistUrl={get(latestVideos.content, "0.playlist_url")}
            displayNavButtons={isDesktop}
          />
        </LatestVideos>
      )}
      <Columns isArticle={false} hasMobileBottomSpace>
        <MainContent data-qa-label="education-hub-main-content">
          <Articles
            data={homeData?.sections ?? []}
            displayNavButtons={isDesktop}
          />
        </MainContent>
        <SideContent data-qa-label="education-hub-side-content">
          <RacingFAQ
            data={faq}
            storyblokClient={storyblokClient}
            defaultOpenIndices={isDesktop ? [] : [0]}
          />
        </SideContent>
      </Columns>
      <WalkthroughModal />
    </Container>
  );
};

export { Homepage, HomePageLoading as EducationHubHomePageLoading };

import { get } from "lodash";
import { WatchQueryFetchPolicy } from "@apollo/client";

import { TalentPickData } from "@tvg/ts-types/TalentPicks";
import { Talent } from "@tvg/ts-types/Talent";
import { RaceProgram, TalentPickGraphData } from "@tvg/ts-types/Race";
import BetUtils from "@tvg/utils/betSelection";
import { mapTalentPicksListFromGraph } from "@tvg/utils/talentPicksUtils";
import tvgConf from "@tvg/conf";
import { isMultiRaceBet } from "@tvg/sh-lib-my-bets/utils/raceDetails";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { getPortByBrand } from "@tvg/utils/generalUtils";
import { RacesApolloClientOptions, UseTalentPicksProps } from "../types";

const highlightedOptions = {
  product: tvgConf().product.toUpperCase(),
  brand: tvgConf().brand.toUpperCase(),
  device: tvgConf().device
};

const QUERY_VARIABLES = {
  ...highlightedOptions
};

const POLL_INTERVAL = 60000;

export const getUniqueTalents = (
  talents: Array<TalentPickData>
): Array<Talent> =>
  talents.reduce(
    (uniqueTalents: Array<Talent>, currentValue) =>
      uniqueTalents.some(
        (elem: Talent) => elem.talentId === currentValue.talent.talentId
      )
        ? uniqueTalents
        : uniqueTalents.concat(currentValue.talent),
    []
  );

export const getTalentPicksWithVisualSelections = (
  talentPicksList: Array<TalentPickGraphData>,
  races?: Array<RaceProgram>
) =>
  mapTalentPicksListFromGraph(talentPicksList).map(
    (talentPick: TalentPickData) => {
      let talentPickRaces: Array<RaceProgram> = [];
      if (races && races.length > 0) {
        const currentRaceIndex = races.findIndex((race) => {
          const trackCode = race?.track?.trackCode || race?.track?.code;
          return (
            race.number &&
            trackCode &&
            +race.number === +talentPick.race &&
            talentPick.track === trackCode
          );
        });

        talentPickRaces =
          currentRaceIndex > -1
            ? [...races].splice(currentRaceIndex, talentPick.selections.length)
            : [];
      } else {
        // Only do this if don't have races list from the track
        const currentTalentPick = talentPicksList.find(
          (tp: TalentPickGraphData) => tp.id === talentPick.id
        );

        const talentPickRace = get(
          currentTalentPick,
          "race"
        ) as unknown as RaceProgram;

        if (talentPickRace) {
          talentPickRaces.push(talentPickRace);
        }
      }

      const isPick = talentPick.wagerType.legCount > 1;

      const visualSelections = BetUtils.getVisualSelectionsWithRaces(
        talentPick.selections,
        talentPickRaces,
        isPick
      );

      return {
        ...talentPick,
        visualSelections,
        numRunnersAllRaces: talentPickRaces.map(
          (race) => race.numWagerableRunners?.toString() || ""
        )
      };
    }
  );

const getProps = (result: { talentPicksList: Array<TalentPickGraphData> }) => {
  const talentPicksList: Array<TalentPickGraphData> = get(
    result,
    "talentPicksList",
    []
  );
  const talentPicksWithVisualSelections: Array<TalentPickData> =
    getTalentPicksWithVisualSelections(talentPicksList);

  return {
    talentPicks: talentPicksWithVisualSelections,
    talentsList: getUniqueTalents(talentPicksWithVisualSelections)
  };
};

export default {
  // @TODO: validar props
  skip: (props: UseTalentPicksProps) =>
    !get(props, "shouldUpdate") || get(props, "skip"),
  options: (props: UseTalentPicksProps) => {
    const trackCode = get(props, "trackCode", undefined);
    const raceNumber = get(props, "raceNumber", undefined);
    const useCache = get(props, "useCache", false);

    const variables = {
      ...QUERY_VARIABLES,
      wagerProfile: get(props, "wagerProfile") || getPortByBrand(),
      trackCode,
      raceNumber
    };

    return {
      skip: !props.wagerProfile || get(props, "skip"),
      fetchPolicy: useCache
        ? "cache-first"
        : ("cache-and-network" as WatchQueryFetchPolicy),
      ssr: false,
      variables
    };
  },
  getProps
};

const getNeededRacesCode = (talentPicks: Array<TalentPickGraphData>) =>
  talentPicks.reduce<Array<string>>((racesCode, talentPick) => {
    const talentPickTrack = talentPick.track || "";
    if (
      isMultiRaceBet(
        talentPick?.wagerType?.abbreviation as WagerTypeCodesEnum
      ) &&
      !racesCode.includes(talentPickTrack)
    ) {
      racesCode.push(talentPickTrack);
    }
    return racesCode;
  }, []);

export const racesApolloClientOptions = ({
  talentPicks = [],
  wagerProfile = ""
}: RacesApolloClientOptions) => {
  const tracksCode = getNeededRacesCode(talentPicks || []);
  return {
    skip: !tracksCode.length,
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
    ssr: false,
    variables: {
      wagerProfile,
      tracksCode
    }
  };
};

import styled, { createGlobalStyle, css } from "styled-components";
import playerIcons from "./icons";

export const FloatingPlayer = styled.div<{ isVideoFloating?: boolean }>`
  ${({ isVideoFloating }) =>
    isVideoFloating &&
    css`
      position: fixed;
      z-index: 9;
      background: var(--fd-colors-core-grey);
      border-radius: var(--fd-radii-border-radius-020);
      overflow: hidden;
      top: 0;
      right: var(--fd-space-space-4);
    `}
`;

export const PlayerContainer = styled.div`
  position: relative;
`;

const JW_SLIDER_HEIGHT = "4px";

const JWPlayerDefaults = css`
  .jwplayer {
    &.jw-stretch-uniform .jw-media video {
      object-fit: contain !important;
    }

    .jw-rightclick,
    .jw-flag-touch .jw-slider-time:not(.jw-chapter-slider-time)::before {
      display: none !important;
    }

    &.jw-flag-fullscreen {
      background-color: var(--fd-colors-core-black) !important;
    }
  }
`;

const FDRPlayerDefaults = css`
  .jwplayer.jw-skin-fdr {
    .jw-wrapper {
      &,
      .jw-controls-backdrop {
        background: linear-gradient(
          0deg,
          rgb(10 10 10 / 70%) 30%,
          rgb(10 10 10 / 20%) 50%
        );
      }

      .jw-aspect {
        background: var(--fd-colors-core-grey-l-3);
        background-size: cover;
      }

      &.jw-reset .jw-preview.jw-reset {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    &.jw-flag-fullscreen .jw-wrapper .jw-aspect {
      background: none;
    }

    .duration {
      padding: var(--fd-space-space-2) var(--fd-space-space-2) 6px;
      text-align: center;
      position: absolute;
      top: 10px;
      right: 10px;
      color: var(--fd-colors-core-white);
      background-color: hsl(201deg 13% 20% / 50%);
      font-size: 1.4rem;
      border-radius: var(--fd-radii-border-radius-010);
      font-family: var(--fd-fonts-font-family-030), system-ui;
      white-space: normal;
      letter-spacing: 1px;
      line-height: 1;
      transition:
        opacity 0.8s,
        transform 0.35s;
    }

    &,
    &.jw-breakpoint--1.jw-flag-small-player {
      .jw-controls .jw-slider-time .jw-slider-container {
        height: ${JW_SLIDER_HEIGHT};

        .jw-rail {
          background-color: var(--fd-colors-core-grey-l-3);
          height: ${JW_SLIDER_HEIGHT};
          border-radius: var(--fd-radii-border-radius-010);
        }

        .jw-progress {
          background-color: var(--fd-colors-core-blue);
          height: ${JW_SLIDER_HEIGHT};
          border-top-left-radius: var(--fd-radii-border-radius-010);
          border-bottom-left-radius: var(--fd-radii-border-radius-010);
        }

        .jw-knob {
          background-color: var(--fd-colors-core-white);
          height: 22px;
          width: 22px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: none;
        }

        .jw-buffer {
          background-color: var(--fd-colors-core-grey-l-3);
          height: ${JW_SLIDER_HEIGHT};
          border-radius: var(--fd-radii-border-radius-010);
          opacity: 0.5;
        }
      }
    }

    .jw-icon-volume {
      &::after {
        display: none;
      }

      .jw-slider-volume {
        .jw-buffer,
        .jw-rail,
        .jw-progress {
          width: 4px;
          border-radius: var(--fd-radii-border-radius-010);
        }

        .jw-buffer {
          background-color: var(--fd-colors-core-grey-l-3);
        }

        .jw-rail {
          background-color: var(--fd-colors-core-grey-l-3);
        }

        .jw-progress {
          background-color: var(--fd-colors-core-blue);
        }

        .jw-knob {
          background-color: var(--fd-colors-core-white);
          height: 22px;
          width: 22px;
          border-radius: 50%;
          border: none;
        }
      }
    }

    .jw-captions .jw-text-track-display .jw-text-track-cue {
      padding: var(--fd-space-space-1) 6px !important;
    }

    /* Controls - play, pause, fullscreen, settings etc. */
    &:not(.jw-breakpoint--1) .jw-controls .jw-controlbar .jw-icon-rewind {
      display: inherit !important;
    }

    .jw-controls {
      .jw-controlbar {
        .jw-icon-playback {
          display: inherit !important;
        }

        .jw-icon-settings {
          display: none;
        }
      }

      .jw-display-controls {
        .jw-display-icon-rewind,
        .jw-display-icon-next,
        .jw-icon-display[aria-label="Play"],
        .jw-icon-display[aria-label="Pause"] {
          display: none;
        }
      }
    }

    .jw-controlbar {
      .jw-button-container {
        padding: var(--fd-space-space-2) var(--fd-space-space-3);

        .jw-icon-inline {
          height: 29px;
        }

        .jw-icon-playback .jw-svg-icon-play,
        .jw-icon-playback .jw-svg-icon-pause,
        .jw-icon-rewind .jw-svg-icon-rewind,
        .jw-icon-volume .jw-svg-icon-volume-0,
        .jw-icon-volume .jw-svg-icon-volume-50,
        .jw-icon-volume .jw-svg-icon-volume-100,
        .jw-icon-fullscreen:not(.jw-fullscreen-ima) .jw-svg-icon-fullscreen-on,
        .jw-icon-cc .jw-svg-icon-cc-on,
        .jw-icon-cc .jw-svg-icon-cc-off {
          background-size: 18px;
          background-repeat: no-repeat;
          background-position: center;

          path {
            display: none !important;
          }
        }

        .jw-icon-playback {
          .jw-svg-icon-play {
            background-image: url(${playerIcons.play});
          }

          .jw-svg-icon-pause {
            background-image: url(${playerIcons.pause});
          }
        }

        .jw-icon-rewind .jw-svg-icon-rewind {
          background-image: url(${playerIcons.rewind10});
        }

        .jw-icon-volume {
          .jw-svg-icon-volume-0 {
            background-image: url(${playerIcons.volumeOff});
          }

          .jw-svg-icon-volume-50 {
            background-image: url(${playerIcons.volumeHalf});
          }

          .jw-svg-icon-volume-100 {
            background-image: url(${playerIcons.volumeOn});
          }
        }

        .jw-icon-fullscreen:not(.jw-fullscreen-ima) .jw-svg-icon-fullscreen-on {
          background-image: url(${playerIcons.expand});
        }

        .jw-icon-cc .jw-svg-icon-cc {
          &-on,
          &-off {
            background-image: url(${playerIcons.closedCaptions});
          }

          &-on {
            border-radius: var(--fd-radii-border-radius-020);
            background-color: var(--fd-colors-core-blue-d-1);
          }

          &-off {
            opacity: 0.75;
          }
        }
      }
    }

    /* Float on scroll display */
    &.jw-breakpoint--1.jw-flag-small-player {
      .jw-controls .jw-controlbar {
        .jw-icon-rewind,
        .jw-icon-volume {
          display: none !important;
        }
      }

      .jw-controlbar {
        bottom: var(--fd-space-space-3);

        .jw-button-container {
          width: fit-content;
          margin-bottom: var(--fd-space-space-3);

          .jw-icon-inline {
            color: var(--fd-colors-core-white);
          }

          .jw-icon-inline svg {
            background-size: 24px;
          }

          .jw-icon-fullscreen:not(.jw-fullscreen-ima) {
            display: inherit !important;
          }
        }

        .jw-slider-horizontal::before {
          display: none !important;
        }
      }
    }

    &.jw-state-complete {
      .jw-preview {
        background: none;
      }

      .jw-button-container {
        display: none !important;
      }

      &:not(.jw-breakpoint--1) .jw-controlbar .jw-slider-horizontal {
        margin-bottom: var(--fd-space-space-3);
      }
    }
  }
`;

export const JWPlayerFDRSkin = createGlobalStyle`
  ${JWPlayerDefaults}
  ${FDRPlayerDefaults}

  /**
  * WebKit video controls configurations
  */
  video::-webkit-media-controls,
  video::-webkit-media-controls-panel {
    display: none !important;
  }
`;

import React from "react";
import { buildQaLabel } from "@tvg/test-utils/utils";
import { TrackNameDisplay } from "../TrackNameDisplay";
import { Container, Block } from "./styled-components";
import { TrackLocationDisplay } from "../TrackLocationDisplay";
import { HeaderProps } from "./types";
import { Mtp } from "../Mtp";
import { RaceIcons } from "../RaceIcons";
import { LOCATION } from "../../utils/constants";

export const Header = ({
  race,
  qaLabel,
  isLocationDisplayed,
  isRaceNumberOnSameLine,
  onClick
}: HeaderProps) => {
  const hasDescription = !!race?.highlighted?.description;

  return (
    <Container
      data-qa-label={qaLabel}
      hasDescription={hasDescription}
      onClick={onClick}
    >
      <TrackNameDisplay
        raceName={race.track.name}
        raceNumber={race.raceNumber || race.number}
        raceHighlightedDescription={race.highlighted?.description}
        qaLabel={qaLabel}
        isRaceNumberOnSameLine={isRaceNumberOnSameLine}
      />
      {isLocationDisplayed ? (
        <TrackLocationDisplay race={race} qaLabel={qaLabel} />
      ) : (
        <Block>
          <Mtp
            qaLabel="qp-header-mtp"
            mtp={race.mtp}
            postTime={race.postTime}
            status={race.status.code}
            size="xs"
            mode={hasDescription ? "dark" : "light"}
          />
          <RaceIcons race={race} qaLabel={buildQaLabel([qaLabel, LOCATION])} />
        </Block>
      )}
    </Container>
  );
};

export default Header;

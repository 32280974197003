import styled from "styled-components";
import { Theme } from "@tvg/design-system";
import { Link } from "react-router-dom";

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

export const HeaderLink = styled(Link)`
  font-weight: 500;
  font-size: var(--fd-space-space-4);
  text-decoration: none;

  /* TODO css variable */
  & > p {
    color: ${({ theme }: { theme: Theme }) => theme.colors.blue_accent["600"]};
  }
`;

import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { fontNormal, fontBold } from "@fdr/static-ui/Typography";

export const RichTextStyled = styled.div<{
  hasPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  line-height: 18px;
  font-size: 14px;
  font-family: ${fontNormal};
  color: ${buildColor("grey", "D4")};
  padding: ${({ hasPadding }) => (hasPadding ? "12px" : "0")};

  b {
    font-family: ${fontBold};
  }

  h3 {
    padding-bottom: 12px;
  }

  ol,
  ul {
    list-style: decimal;
    padding-left: 12px;

    li {
      padding-left: 4px;
    }
  }
`;

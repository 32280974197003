import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  min-width: 292px;
  background-color: var(--fd-colors-background-surface);
`;

export default function VideoCardLoading() {
  return (
    <Container>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="263"
        viewBox="0 0 300 263"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_1_155053)">
          <g clipPath="url(#clip0_1_155053)">
            <rect x="4" y="2" width="292" height="255" rx="8" fill="white" />
            <rect
              width="292"
              height="153"
              transform="translate(4 2)"
              fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            />
            <rect
              x="20"
              y="171"
              width="219.939"
              height="18"
              rx="2"
              fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            />
            <rect
              x="20"
              y="197"
              width="172.814"
              height="18"
              rx="2"
              fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            />
            <rect
              x="20"
              y="223"
              width="73.6045"
              height="18"
              rx="2"
              fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            />
            <rect
              x="222.931"
              y="223"
              width="57.0693"
              height="18"
              rx="2"
              fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_1_155053"
            x="0"
            y="0"
            width="300"
            height="263"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="2" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1_155053"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1_155053"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_1_155053">
            <rect x="4" y="2" width="292" height="255" rx="8" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
}

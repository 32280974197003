import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
  height: 65px;
`;

export default function SectionHeaderLoading() {
  return (
    <Container>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="651"
        height="65"
        viewBox="0 0 651 65"
        fill="none"
      >
        <rect
          width="651"
          height="64"
          transform="translate(0 0.5)"
          fill="white"
        />
        <rect
          x="16"
          y="23.5"
          width="200"
          height="18"
          rx="2"
          fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
        />
      </SVG>
    </Container>
  );
}

import React from "react";
import { get, noop } from "lodash";

import {
  TagLabel,
  PromoTagContainer,
  OptedInTagContainer,
  TransparentTagContainer,
  CoupledTagContainer,
  EasyTagContainer,
  HardTagContainer,
  VeryHardTagContainer,
  AverageTagContainer
} from "./styled-components";

const Tag = ({
  name,
  isCollapsed,
  qaLabel,
  tagType,
  className,
  size,
  fontSize,
  onClick,
  useNewLayout
}) => {
  const TagTypeWrapper = (() => {
    switch (tagType) {
      case "OptedInTag":
        return OptedInTagContainer;
      case "TransparentTag":
        return TransparentTagContainer;
      case "CoupledTag":
        return CoupledTagContainer;
      case "easyTag":
        return EasyTagContainer;
      case "hardTag":
        return HardTagContainer;
      case "veryhardTag":
        return VeryHardTagContainer;
      case "averageTag":
        return AverageTagContainer;
      default:
        return PromoTagContainer;
    }
  })();

  let hidePromos = false;
  if (typeof window !== "undefined") {
    hidePromos = get(
      window,
      "__PRELOADED_STATE__.capi.featureToggles.forceHidePromos",
      false
    );
  }

  return hidePromos &&
    ["PromoTag", "OptedInTag"].indexOf(tagType) > -1 ? null : (
    <TagTypeWrapper
      className={className}
      size={size}
      isCollapsed={isCollapsed}
      data-qa-label={`${qaLabel}Container${tagType}`}
      onClickCapture={onClick}
      useNewLayout={useNewLayout}
    >
      {name !== "PENDING" && (
        <TagLabel fontSize={fontSize} data-qa-label={`${qaLabel}Label`}>
          {isCollapsed ? "P" : !isCollapsed && name}
        </TagLabel>
      )}
    </TagTypeWrapper>
  );
};

Tag.defaultProps = {
  name: "Promo",
  isCollapsed: false,
  qaLabel: "tag",
  tagType: "PromoTag",
  className: "",
  size: 16,
  fontSize: 12,
  onClick: noop,
  useNewLayout: false
};

export default Tag;

import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { JWVideoPlaylist } from "../components/PlaylistVideos/types";
import { getJWPlaylistVideos } from "../utils/jwplayer";
import { getURPEducationHubMessages } from "../selectors";

const useFetchPlaylist = (url: string | undefined) => {
  const { playlistDefaultSettings } = useSelector(getURPEducationHubMessages);

  const [data, setData] = useState<JWVideoPlaylist>();
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);

  const fetchPlaylist = useCallback(
    async (jwPlayerUrl: string | undefined) => {
      setLoading(true);
      try {
        const {
          playlistUrl: defaultPlaylistUrl,
          playerScript,
          licenseKey
        } = playlistDefaultSettings;
        if (jwPlayerUrl || defaultPlaylistUrl) {
          const playlistData = await getJWPlaylistVideos(
            jwPlayerUrl || defaultPlaylistUrl,
            playerScript,
            licenseKey
          );
          setData(playlistData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [playlistDefaultSettings]
  );

  useEffect(() => {
    fetchPlaylist(url);
  }, [url]);

  return { data, isLoading, isError };
};

export default useFetchPlaylist;

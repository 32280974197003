/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { titleFont } from "@fdr/static-ui/Typography";

export const buildTitle = (titleDataInit) => {
  const defaultProp = {
    tag: "h1",
    fontSize: -1,
    color: "inherit",
    uppercase: false,
    ellipsis: false
  };
  const titleData = { ...defaultProp, ...titleDataInit };

  const TitleAtom = styled[titleData.tag].attrs({
    "data-qa-label": titleData.qaLabel
  })`
    font-family: ${titleFont};
    ${titleData.fontSize >= 0 && `font-size: ${titleData.fontSize}px;`};
    text-transform: ${titleData.uppercase && "uppercase"};
    color: ${titleData.color !== "inherit" && titleData.color};
    margin: 0;
  `;

  return !titleData.ellipsis
    ? TitleAtom
    : styled(TitleAtom)`
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `;
};

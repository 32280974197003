import styled from "styled-components";

export const Container = styled.div`
  padding: var(--fd-space-space-4);
  height: 87px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: var(--fd-space-space-3);
`;

import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import service from "@tvg/api/pes";
import Container from "@tvg/atomic-ui/_static/BaseComponents/Containers";
import setHomepageOrder from "./store/actions";
import componentMap from "./componentMap";
import defaultOrder from "./defaultOrder";

export class Home extends PureComponent {
  static defaultProps = {
    device: "mobile",
    shouldUpdate: true,
    homepageOrder: null,
    seoContent: "",
    quickLinksExpanded: false,
    useTopRacesDeskDS: false,
    useTopTracksDS: false,
    usePromosWidgetDS: false,
    usePreviousWinnersDS: false,
    useURPTipsAndPicks: false,
    graphClients: {}
  };

  componentDidMount() {
    return !this.props.homepageOrder && this.getHomepageOrder();
  }

  getHomepageOrder = () =>
    service
      .fetchHomepageConfiguration()
      .then((data) => this.props.dispatch(setHomepageOrder(data)))
      .catch(() =>
        this.props.dispatch(setHomepageOrder(defaultOrder[this.props.device]))
      );

  renderHomeGrid(context) {
    const rowFixed = [];
    const rowTop = [];
    const rowFull = [];
    const rowBottom = [];
    const leftCol = [];
    const rightCol = [];

    const componentProps = {
      device: this.props.device,
      shouldUpdate: this.props.shouldUpdate,
      message: this.props.seoContent
    };

    if (this.props.homepageOrder) {
      this.props.homepageOrder.map((item) => {
        if (componentMap[item.name]) {
          let container;
          if (this.props.device === "desktop") {
            switch (componentMap[item.name].type) {
              case "fixed":
                container = rowFixed;
                break;
              case "top":
                container = rowTop;
                break;
              case "full":
                container = rowFull;
                break;
              case "large":
                container = leftCol;
                break;
              case "small":
                container = rightCol;
                break;
              case "bottom":
                container = rowBottom;
                break;
              default:
                container = rowTop;
            }
          } else {
            switch (componentMap[item.name].type) {
              case "fixed":
                container = rowFixed;
                break;
              case "top":
                container = rowTop;
                break;
              default:
                container = rowFull;
            }
          }

          // Render component and add to container
          container.push(
            componentMap[item.name].render(
              {
                ...componentProps,
                title: item.description,
                quickLinksExpanded: this.props.quickLinksExpanded,
                useTopRacesDeskDS: this.props.useTopRacesDeskDS,
                useTopTracksDS: this.props.useTopTracksDS,
                usePromosWidgetDS: this.props.usePromosWidgetDS,
                usePreviousWinnersDS: this.props.usePreviousWinnersDS,
                useURPTipsAndPicks: this.props.useURPTipsAndPicks
              },
              context
            )
          );
        }
        return item;
      });

      return (
        <Fragment>
          {rowFixed.length > 0 &&
            this.props.device === "desktop" &&
            this.props.quickLinksExpanded &&
            rowFixed}
          <Container.HomeGridContainer device={this.props.device}>
            {this.props.device === "desktop" ? (
              <Fragment>
                {rowTop.length > 0 && (
                  <Container.HomeFullRow device="desktop">
                    {rowTop}
                  </Container.HomeFullRow>
                )}
                {rowFull.length > 0 && (
                  <Container.HomeFullRow device={this.props.device}>
                    {rowFull}
                  </Container.HomeFullRow>
                )}
                {leftCol.length > 0 && (
                  <Container.HomeLeftColumn>{leftCol}</Container.HomeLeftColumn>
                )}
                {rightCol.length > 0 && (
                  <Container.HomeRightColumn>
                    {rightCol}
                  </Container.HomeRightColumn>
                )}
                {rowTop.length > 0 && (
                  <Container.HomeFullRow device={this.props.device}>
                    {rowBottom}
                  </Container.HomeFullRow>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {rowFixed.length > 0 && rowFixed}
                {rowTop.length > 0 && (
                  <Container.HomeFullRow
                    device={this.props.device}
                    hasMarginTopFixed={
                      rowFixed.length > 0 && this.props.device === "tablet"
                    }
                    quickLinksExpanded={this.props.quickLinksExpanded}
                  >
                    {rowTop}
                  </Container.HomeFullRow>
                )}
                {rowFull.length > 0 &&
                  rowFull.map((Component, i) => (
                    <Container.HomeFullRow
                      device={this.props.device}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`row${i}`}
                      hasMarginBottom={i < rowFull.length - 1}
                      hasMarginTop={rowFixed.length > 0 && rowTop.length === 0}
                      hasMarginTopFixed={
                        rowFixed.length > 0 && this.props.device === "tablet"
                      }
                      quickLinksExpanded={this.props.quickLinksExpanded}
                    >
                      {Component}
                    </Container.HomeFullRow>
                  ))}
              </Fragment>
            )}
          </Container.HomeGridContainer>
        </Fragment>
      );
    }
    return <Fragment />;
  }

  render = () =>
    this.renderHomeGrid({
      rdaClient: get(this.props, "graphClients.rdaClient", null)
    });
}

export default connect(
  (store) => ({
    homepageOrder: get(store, "home.homepageOrder", null),
    quickLinksExpanded: get(
      store,
      "capi.featureToggles.showQuickLinksExpanded",
      false
    ),
    useTopRacesDeskDS: get(
      store,
      "capi.featureToggles.useTopRacesDeskDS",
      false
    ),
    useTopTracksDS: get(store, "capi.featureToggles.useTopTracksDS", false),
    usePromosWidgetDS: get(store, "capi.featureToggles.usePromosWidgetDS"),
    usePreviousWinnersDS: get(
      store,
      "capi.featureToggles.usePreviousWinnersDS",
      false
    ),
    useURPTipsAndPicks: get(
      store,
      "capi.featureToggles.enableURPTipsAndPicks",
      false
    )
  }),
  (dispatch) => ({ dispatch })
)(Home);

import styled from "styled-components";

export const Body = styled.div`
  padding: var(--fd-space-space-4);
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-4);
`;

export const Description = styled.article`
  font-family: var(--fd-fonts-font-family-030);
  text-align: justify;
  color: var(--fd-colors-border-subtle);
`;

export const VideoPlayerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

import React, { useEffect, useState } from "react";
import { noop } from "lodash";
import mediator from "@tvg/mediator";
import { Header, useTheme } from "@tvg/design-system";
import { Modal } from "@tvg/design-system/web";
import type { PreviousWinnersContainerProps } from "../../types";
import { MAX_WINNERS_HIGHLIGHT, ALL_WINNERS_INDEX } from "../../constants";
import PreviousWinnersList from "../PreviousWinnersList";
import {
  Container,
  UpperSection,
  LinkContainerDesk
} from "./styled-components";

const PreviousWinnersContainer: React.FC<PreviousWinnersContainerProps> = (
  props
) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const theme = useTheme();

  const allWinners = props.winners[ALL_WINNERS_INDEX];
  const totalWinners = allWinners.length;
  const hasUniqueWinner = totalWinners === 1;
  const winnersHighlighted = allWinners.slice(0, MAX_WINNERS_HIGHLIGHT);
  const showSeeAllElement = totalWinners > winnersHighlighted.length;

  // TODO: Remove this and control the visibility with the redux store
  useEffect(() => {
    mediator.base.subscribe("PREVIOUS_WINNERS:SEE_ALL", () => {
      setIsModalOpen(true);
    });
  }, []);

  const onSeeAllModalOpen = () => {
    mediator.base.dispatch({ type: "PREVIOUS_WINNERS:SEE_ALL" });
  };

  const onSeeAllModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Container
      theme={theme}
      isDesktop={props.isDesktop}
      data-qa-label={`${props.qaLabel}Container`}
    >
      {props.isDesktop && (
        <UpperSection theme={theme} isDesktop={props.isDesktop}>
          <Header
            tag="h3"
            fontWeight="bold"
            data-qa-label={`${props.qaLabel}Title`}
          >
            {props.title}
          </Header>
          {showSeeAllElement && (
            <LinkContainerDesk
              theme={theme}
              onClick={onSeeAllModalOpen}
              data-qa-label={`${props.qaLabel}SeeAll`}
            >
              See all
            </LinkContainerDesk>
          )}
        </UpperSection>
      )}
      <>
        <PreviousWinnersList
          isModal={false}
          isDesktop={props.isDesktop}
          winners={winnersHighlighted}
          hasUniqueWinner={hasUniqueWinner}
          showSeeAllElement={showSeeAllElement}
          onModalOpen={onSeeAllModalOpen}
          onSelection={props.onSelection}
        />
        <Modal
          headerProps={{ title: props.modalTitle, showCloseButton: true }}
          hasMaxHeight
          maxHeight="70vh"
          isOpen={isModalOpen}
          onClose={onSeeAllModalClose}
          padding={0}
        >
          <PreviousWinnersList
            isModal
            isDesktop={props.isDesktop}
            winners={allWinners}
            onModalOpen={noop}
            onSelection={props.onSelection}
          />
        </Modal>
      </>
    </Container>
  );
};

export default PreviousWinnersContainer;

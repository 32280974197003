import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  background: var(--fd-colors-background-surface);
`;

const Base = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SVG = styled.svg`
  display: flex;
  flex-direction: column;
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Rect = styled.rect`
  fill: var(--fd-colors-content-loading-default);
`;

const Circle = styled.circle`
  fill: var(--fd-colors-content-loading-default);
`;

const Loading = () => (
  <Container data-qa-label="walkthrough-modal-loading">
    <Base>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="350"
        viewBox="0 0 393 350"
        fill="none"
        preserveAspectRatio="none"
      >
        <Rect width="393" height="350" />
      </SVG>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="266"
        viewBox="0 0 393 266"
        fill="none"
        preserveAspectRatio="none"
      >
        <Rect x="72" y="24" width="247" height="24" />
        <Rect x="110" y="56" width="171" height="24" />
        <Rect x="24" y="96" width="343" height="16" />
        <Rect x="61" y="120" width="269" height="16" />
        <Rect x="35" y="144" width="322" height="16" />
        <Rect x="24" y="168" width="343" height="16" />
        <Rect x="61" y="192" width="269" height="16" />
        <Rect x="77" y="216" width="238" height="16" />
      </SVG>
    </Base>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="74"
      viewBox="0 0 393 74"
      fill="none"
      preserveAspectRatio="none"
    >
      <Rect x="32" width="88" height="44" />
      <Circle cx="172" cy="22" r="4" />
      <Circle cx="184" cy="22" r="4" />
      <Circle cx="196" cy="22" r="4" />
      <Circle cx="208" cy="22" r="4" />
      <Circle cx="220" cy="22" r="4" />
      <Rect x="273" width="88" height="44" />
    </SVG>
  </Container>
);

export default Loading;

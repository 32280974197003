import tvgConf from "@tvg/conf";

export enum STORYBLOK_CONTENT_PATH {
  PROMO_ONBOARDING = "promo-onboarding",
  HOMEPAGE_CONFIG = "homepage",
  EDUCATIONAL_HUB = "educational-hub",
  BETA_PROGRAM = "beta-program",
  BANNERS_CONFIG = "event-banners",
  BET_PROMO_BANNER = "banners"
}

export enum STORYBLOK_SLUGS {
  BANNERS = "banners",
  HOMEPAGE_DESK = "desktop/homepage-sorting-desk",
  HOMEPAGE_TVG5 = "tvg5/homepage-sorting-desk",
  HOMEPAGE_SA = "sa/homepage-sorting-sa",
  HOMEPAGE_XSELL = "xsell/homepage-sorting-xsell",
  BET_PROMO_BANNER = "bet-promo-banner"
}

export const STORYBLOK_URL = "https://api.storyblok.com/v1/cdn/stories";
export const getStoryblokSpaceUrl = (contentPath: STORYBLOK_CONTENT_PATH) =>
  `https://api.storyblok.com/v1/cdn/spaces/${contentPath}`;

export const {
  storyblokToken: STORYBLOK_TOKEN,
  storyblokVersion: STORYBLOK_VERSION
} = tvgConf().config();

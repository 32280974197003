import { StoryblokHomeContent, StoryblokPageContent } from "../types";

export interface ISetStoredSlugAction {
  key: string;
  data: StoryblokHomeContent | StoryblokPageContent;
}

export interface SetStoreSlugAction {
  type: "SET_STORED_CONTENT_SLUGS";
  payload: ISetStoredSlugAction;
}

export interface SetSelectedSlugAction {
  type: "SET_SELECTED_SLUG";
  payload: string;
}

export const setStoredContentSlugs = (payload: ISetStoredSlugAction) => ({
  type: "SET_STORED_CONTENT_SLUGS",
  payload
});

export const setSelectedSlug = (payload: string | undefined) => ({
  type: "SET_SELECTED_SLUG",
  payload
});

export const openVideoModal = () => ({
  type: "OPEN_FULLSCREEN_VIDEO_MODAL"
});

export const closeVideoModal = () => ({
  type: "CLOSE_FULLSCREEN_VIDEO_MODAL"
});

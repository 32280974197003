import styled, { css } from "styled-components";
import { fontBold, fontNormal, defaultSize } from "@fdr/static-ui/Typography";
import buildColor from "@fdr/static-ui/ColorPalette";

export const ListContainer = styled.ul`
  width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 12px 0;

  &::after {
    content: "";
    display: inline-block;
    min-width: 12px;
    height: 112px;
  }
`;

export const ItemListContainer = styled.li`
  border-radius: 4px;
  border: 1px solid ${buildColor("grey", "L2")};
  min-width: 142px;
  overflow: hidden;
  &:first-child {
    margin-left: 16px;
  }

  &:not(:first-child) {
    margin-left: 8px;
  }

  & > a {
    width: 100%;
    display: block;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin: 0 0 13px;
  border: 1px solid ${buildColor("grey", "L2")};

  :hover {
    background-color: ${buildColor("navy", "100")};
  }
`;

export const ItemWrapper = styled.section`
  width: 100%;
  background-color: ${buildColor("white")};
`;

export const PanelContainer = styled.div`
  min-width: 142px;
  border-radius: 4px;
`;

export const ItemTitle = styled.span`
  color: ${buildColor("navy", "D2")};
  font-family: ${fontBold};
  font-weight: 700;
  font-size: ${defaultSize};
  padding-bottom: 4px;
  line-height: 125%;
`;

export const ItemDescription = styled.span`
  font-size: 12px;
  font-family: ${fontNormal};
  color: ${buildColor("navy", "D2")};
`;

export const InformationWrapper = styled.div<{ isHeightChanged: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${buildColor("white")};
  padding: 12px;
  height: ${({ isHeightChanged }) => (isHeightChanged ? "82px" : "122px")};
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${fontNormal};
  font-size: ${defaultSize};
  font-weight: 400;
  padding: 8px 12px;
  height: 34px;
  width: 100%;
  color: ${buildColor("blue", "D1")};
  background-color: ${buildColor("grey", "L5")};
  border-top: 1px solid ${buildColor("grey", "L2")};
  &:hover {
    background-color: ${buildColor("grey", "L4")};
  }
`;

export const TagIconsWrapper = styled.div`
  padding-bottom: 12px;
`;

export const TagIcon = styled.div<{ size: number }>`
  position: relative;
  display: inline-block;
  ${({ size }) =>
    size &&
    css`
      height: ${size}px;
      width: ${size}px;
    `};
`;

import { DefaultRootState } from "react-redux";
import get from "lodash/get";
import parseCAPIMessage from "@tvg/utils/capiUtils";
import {
  StoryblokHomeContent,
  StoryblokPageContent,
  URPEducationHubMessages,
  DefaultPlaylistSettings,
  StoryblokWalkthroughContent
} from "../types";
import { urpEducationHubDefaultMessages } from "./defaultValues";

export const getURPEducationHubMessages = (
  store: DefaultRootState
): URPEducationHubMessages =>
  parseCAPIMessage(
    store,
    "capi.messages.urpEducationHubMessages",
    urpEducationHubDefaultMessages
  );

export const getSelectedSlug = (store: DefaultRootState): string =>
  get(store, "educationHub.selectedSlug", "");

export const getStoredSlugContent = <
  T extends
    | StoryblokHomeContent
    | StoryblokPageContent
    | StoryblokWalkthroughContent
>(
  store: DefaultRootState,
  selectedSlug: string
): T | undefined => get(store, `educationHub.storedSlugsData.${selectedSlug}`);

export const getFullscreenModalState = (store: DefaultRootState): boolean =>
  get(store, "modal.isFullscreenVideoOpen", false);

export const getDefaultPlaylistSettings = (
  store: DefaultRootState
): DefaultPlaylistSettings =>
  parseCAPIMessage(store, "capi.messages.homePlaylistSettings", {});

export const getEnableURPEducationHub = (store: DefaultRootState): boolean =>
  get(store, "capi.featureToggles.enableURPEducationHub", false);

export const getEnableURPEducationHubXSell = (
  store: DefaultRootState
): boolean =>
  get(store, "capi.featureToggles.enableURPEducationHubXSell", false);

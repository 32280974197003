import React, { Component } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import HomeGrid from "@tvg/home";
import ApolloContext from "@tvg/utils/apolloContext";
import { withTheme } from "styled-components";

import perf from "../utils/perf";

const mapStateToProps = (store) => ({
  areQuickLinksVisible: get(store.quickLinks, "rendering.areQuickLinksVisible"),
  hasRequestedSession: get(store, "userData.hasRequested"),
  hideMobileHomeRaces: get(
    store,
    "capi.featureToggles.hideMobileHomeRaces",
    false
  ),
  useQuickLinksExpanded: get(
    store,
    "capi.featureToggles.showQuickLinksExpanded",
    false
  ),
  seoContentHome: get(store, "capi.messages.seoContentHome", "")
});

export class Home extends Component {
  static defaultProps = {
    transitionState: "exit",
    isLogged: false,
    device: "",
    areQuickLinksVisible: false,
    hasRequestedSession: false,
    hideMobileHomeRaces: false,
    useQuickLinksExpanded: false
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.isLogged !== nextProps.isLogged ||
      (nextProps.transitionState === "entered" &&
        this.props.transitionState !== "entered") ||
      nextProps.areQuickLinksVisible !== this.props.areQuickLinksVisible ||
      nextProps.seoContentHome !== this.props.seoContentHome
    );
  }

  componentDidUpdate() {
    perf.measure("page_render", { page: "home" });
  }

  render() {
    const shouldUpdate = this.props.transitionState === "entered";

    return (
      <ApolloContext.Consumer>
        {(value) => (
          <React.Fragment>
            <HomeGrid
              shouldUpdate={shouldUpdate}
              device={this.props.device}
              seoContent={this.props.seoContentHome}
              graphClients={value}
            />
          </React.Fragment>
        )}
      </ApolloContext.Consumer>
    );
  }
}

export default connect(mapStateToProps, null)(withTheme(Home));

import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
  user-select: none;
  border-radius: var(--fd-radii-border-radius-020);
  overflow: hidden;
`;

export const ItemContainer = styled.div`
  padding-bottom: var(--fd-space-space-1);
`;

export const CardsWrapper = styled.div`
  display: flex;
  gap: var(--fd-space-space-3);
  padding: var(--fd-space-space-3);
`;

export const TitleWrapper = styled.div`
  display: flex;
  padding: var(--fd-space-space-5);
  height: 100%;
  max-height: 25px;
  overflow: hidden;
  margin-bottom: var(--fd-space-space-3);
  width: 100%;
  max-width: 200px;
  background: var(--fd-colors-background-surface);
`;

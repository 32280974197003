import { History } from "@tvg/ts-types/History";

import type {
  PreviousWinnerWRCResponseLists,
  Race,
  PreviousWinnerWRCResponse
} from "@tvg/ts-types/PreviousWinners";

import mediator from "@tvg/mediator";
import { buildBetSlipUrl } from "@tvg/formatter/url";
import { isTvg5, isURP } from "@tvg/utils/generalUtils";
import { useURPProgramPageOnXSell } from "@urp/amplitude-experiments";

export const navigateToBetSlip = (
  url: string,
  winner: PreviousWinnerWRCResponse,
  history: History
) => {
  mediator.base.dispatch({
    type: "TVG4_NAVIGATION",
    payload: { route: url }
  });
  const newUrl = buildBetSlipUrl(
    winner.currentRace.trackCode,
    winner.currentRace.raceNumber.toString(),
    winner.currentRace.bettingInterestNumber,
    false
  );

  const shouldUseUrpPPOnXSell = useURPProgramPageOnXSell();

  if (isTvg5() || isURP || shouldUseUrpPPOnXSell) {
    const runner = winner.currentRace.bettingInterestNumber;
    history.push(`${url}&selectedrunner=${runner}&betType=10`);
  } else {
    window.open(newUrl, "", "width=535,height=778");
  }
};

export const buildPreviousWinners = (
  winnersPill: PreviousWinnerWRCResponseLists,
  racesList: Race[]
) =>
  winnersPill.map((winnerList) =>
    winnerList.map((winner) => {
      const race = racesList.find(
        (raceEl) => raceEl.tvgRaceId === winner.currentRace.raceId
      );

      return race
        ? {
            ...winner,
            currentRace: {
              ...race,
              ...winner.currentRace,
              mtp: race.mtp,
              postTime: race.postTime
            }
          }
        : winner;
    })
  );

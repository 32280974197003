import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";

export const Container = styled.div``;

export const FooterWrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  background-color: ${buildColor("white")};
  color: ${buildColor("blue", "D1")};
  min-height: 68px;
  height: 68px;
  font-size: 16px;

  &::after {
    content: "";
    width: 100%;
    height: 50%;
    background-color: ${buildColor("white")};
    position: absolute;
    bottom: calc(env(safe-area-inset-bottom) * -1);
    z-index: -1;
  }
`;

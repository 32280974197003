import React from "react";
import { LinkCardList } from "@tvg/design-system/web";
import { Props } from "./types";
import { renderPoolsArray } from "./renderer";
import { QA_LABEL } from "../../qaLabels";
import { ARRAY_LIMIT } from "./constants";

const DesktopView = ({
  pools,
  onPoolClick,
  highlightsTitle,
  upcomingTitle
}: Props) => (
  <LinkCardList qaLabel={QA_LABEL.body.desktop.list}>
    {[
      renderPoolsArray(
        pools.slice(0, ARRAY_LIMIT),
        onPoolClick,
        highlightsTitle,
        upcomingTitle
      )
    ]}
  </LinkCardList>
);

export default DesktopView;

import styled from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--fd-colors-background-surface);
  overflow: hidden;

  @media screen and ${breakpoints.tablet.max.sm} {
    flex-grow: 1;
  }
`;

export const PlayerWrapper = styled.div<{ height: number }>`
  position: relative;
  background-color: var(--fd-colors-core-black);
  overflow: hidden;
  height: ${({ height }) => `${height}px`};

  @media screen and ${breakpoints.tablet.max.sm} {
    flex-grow: 1;
    height: initial;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: transparent;
  padding: var(--fd-space-space-4);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--fd-space-space-3);
  padding: var(--fd-space-space-3);
  background-color: var(--fd-colors-background-surface);

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: 0;
  }
`;

export const DescriptionLabel = styled.p`
  color: var(--fd-colors-content-strong);
  font-family: var(--fd-fonts-font-family-010), system-ui;
  font-size: var(--fd-font-sizes-font-size-040);
  line-height: 18px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--fd-space-space-2);

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: 0 var(--fd-space-space-4) var(--fd-space-space-4);
  }
`;

import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const Container = styled.section<{
  hasFooter: boolean;
  isOnHomepage: boolean;
}>`
  display: flex;
  flex-direction: column;
  background: var(--fd-colors-background-surface);
  border-radius: var(--fd-radii-border-radius-020);
  overflow: hidden;

  @media screen and ${breakpoints.tablet.max.sm} {
    padding-bottom: ${({ hasFooter }) => (hasFooter ? "61px" : "0")};

    ${({ isOnHomepage }) =>
      !isOnHomepage &&
      css`
        border-bottom: var(--fd-border-widths-border-width-010)
          var(--fd-border-styles-border-style-solid-style)
          var(--fd-colors-border-subtle);
        border-radius: 0;
      `}
  }
`;

export const Title = styled.div<{ divider: boolean }>`
  width: 100%;
  padding: var(--fd-space-space-6) var(--fd-space-space-4);
  ${({ divider }) =>
    divider &&
    css`
      border-bottom: 1px solid var(--fd-colors-border-subtle);
    `};

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: var(--fd-space-space-3);
    border-bottom: none;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  padding: var(--fd-space-space-4);
  border-top: 1px solid var(--fd-colors-border-subtle);
  background: var(--fd-colors-background-surface);

  @media screen and ${breakpoints.tablet.max.sm} {
    margin-top: auto;
    position: fixed;
    bottom: 0;
    padding: var(--fd-space-space-2) var(--fd-space-space-4)
      calc(var(--fd-space-space-2) + env(safe-area-inset-bottom));
  }
`;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: var(--fd-space-space-4);
  border-left: 1px solid var(--fd-colors-border-default);

  #rich-text > p:only-child {
    margin-bottom: 0;
  }
`;

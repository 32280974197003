import styled from "styled-components";

export const CardWrapper = styled.div`
  min-width: 182px;
  height: 174px;
  border: 1px solid
    ${({ theme: { colorTokens } }) => colorTokens.border.default};
  position: relative;
  border-radius: 4px;
`;

export const TextWrapper = styled.div`
  padding: ${({ theme }) => theme.space["space-5"]};
`;

export const Title = styled.div`
  margin-top: ${({ theme }) => theme.space["space-3"]};
  margin-bottom: ${({ theme }) => theme.space["space-1"]};
`;

export const Text = styled.div`
  margin: 0 0 auto 0;
`;

export const LearnBox = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  background-color: ${({ theme: { colorTokens } }) =>
    colorTokens.background.layer};
  border-top: 1px solid
    ${({ theme: { colorTokens } }) => colorTokens.border.default};
  bottom: 0;
  margin-top: auto;
  padding: ${({ theme }) =>
    `${theme.space["space-4"]} ${theme.space["space-5"]}`};
  position: absolute;
  width: 100%;
`;

import React from "react";
import { Paragraph } from "@tvg/design-system/web";
import { Playlist } from "../PlaylistVideos/types";
import PlayIcon from "./play";
import {
  VideoCardButton,
  Thumbnail,
  IconContainer,
  Duration,
  Description,
  Row
} from "./styled-components";

interface VideoCardProps {
  item: Playlist;
  qaLabel?: string;
  onClick: (item: Playlist) => void;
}

const VideoCard = ({
  qaLabel = "video-card-button",
  item,
  onClick
}: VideoCardProps) => (
  <VideoCardButton data-qa-label={qaLabel} onClick={() => onClick(item)}>
    <Thumbnail source={item.video.thumbnail}>
      <IconContainer data-qa-label={`${qaLabel}-play-icon`}>
        <PlayIcon />
      </IconContainer>

      <Duration data-qa-label={`${qaLabel}-duration`}>
        {item.video.duration}
      </Duration>
    </Thumbnail>

    <Description>
      <Paragraph
        qaLabel={`${qaLabel}-title`}
        lineHeight="125%"
        fontFamily="regular"
        fontSize="s"
        color="--fd-colors-content-strong"
      >
        {item.description.title}
      </Paragraph>

      <Row>
        <Paragraph
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          qaLabel={`${qaLabel}-category`}
          fontFamily="condensedRegular"
          textTransform="uppercase"
          fontSize="xs"
          color="--fd-colors-content-subtle"
        >
          {item.description.category}
        </Paragraph>

        <Paragraph
          qaLabel={`${qaLabel}-uploaded-at`}
          fontFamily="condensedRegular"
          textTransform="uppercase"
          fontSize="xs"
          color="--fd-colors-content-subtle"
        >
          {item.description.uploadedAt}
        </Paragraph>
      </Row>
    </Description>
  </VideoCardButton>
);

export default VideoCard;

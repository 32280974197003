import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const Container = styled.div`
  width: 292px;
  height: 259px;
  display: flex;
  flex-direction: column;
  background: var(--fd-colors-background-surface);
`;

const Card = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: var(--fd-radii-border-radius-030);
  background: var(--fd-colors-background-surface);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
`;

const SVG = styled.svg`
  display: flex;
  flex-direction: column;
  animation: ${pulseAnimation} 1.25s infinite;
`;

const Rect = styled.rect`
  fill: var(--fd-colors-content-loading-default);
`;

const Loading = () => (
  <Container data-qa-label="video-card-loading">
    <Card>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="292"
        height="168"
        viewBox="0 0 292 168"
        fill="none"
        preserveAspectRatio="none"
      >
        <Rect width="292" height="168" />
      </SVG>
      <SVG
        xmlns="http://www.w3.org/2000/svg"
        width="292"
        height="87"
        viewBox="0 0 292 102"
        fill="none"
        preserveAspectRatio="none"
      >
        <Rect x="16" y="16" width="219.939" height="18" rx="2" />
        <Rect x="16" y="42" width="172.814" height="18" rx="2" />
        <Rect x="16" y="68" width="73.6045" height="18" rx="2" />
        <Rect x="218.931" y="68" width="57.0693" height="18" rx="2" />
      </SVG>
    </Card>
  </Container>
);

export default Loading;

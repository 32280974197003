import styled, { css } from "styled-components";

export const ContentWrapper = styled.div`
  section:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

export const ReferenceWrapper = styled.div<{
  disableTopBorder?: boolean;
  isModal?: boolean;
  isLastEl?: boolean;
}>`
  ${({ disableTopBorder }) =>
    disableTopBorder
      ? ` section:first-of-type {
    border-top: 0;
  }`
      : ""}

  scroll-margin: ${({ isModal }) => (isModal ? "52px" : "112px")};
  ${({ isLastEl }) =>
    isLastEl &&
    css`
      min-height: 100vh;
    `}
`;

export const TabGroupContainer = styled.div<{ isFixedBannerVisible?: boolean }>`
  position: sticky;
  z-index: 999;
  top: ${({ isFixedBannerVisible }) =>
    isFixedBannerVisible ? "94px" : "60px"};
`;

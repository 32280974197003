import React from "react";
import StoryblokClient, { Richtext } from "storyblok-js-client";
import DOMPurify from "dompurify";
import tvgConf from "@tvg/conf";
import BetTypeInfo from "@fdr/atomic-ui/_organisms/BetTypeInfo";
import { ContentBetTypes } from "@fdr/tutorials/src/types";
import { BetTypeItem } from "@fdr/types/ts/ProgramPage";
import { BetCategoryTitle } from "./styled-components";

const storyblokToken = tvgConf().config("storyblokToken");
const betTypeKey = "PICK_BET_NUMBER";

const storyblokClient = new StoryblokClient({
  accessToken: storyblokToken,
  cache: {
    clear: "auto",
    type: "memory"
  }
});

const replacePlaceholderValue = (
  bet_description: Richtext,
  bettingType?: string[]
) => {
  const value =
    typeof window !== "undefined"
      ? window.localStorage.getItem(betTypeKey) || ""
      : "";

  const richTextStr = JSON.stringify(bet_description);
  const valueToReplace = bettingType?.length && value ? value : "3-10";
  const richTextResult = richTextStr.replace(`[${betTypeKey}]`, valueToReplace);

  return JSON.parse(richTextResult);
};

const formatBetType = (
  data: ContentBetTypes,
  bettingType?: string[]
): BetTypeItem[] =>
  data.bet_type.map((item) => ({
    title: item.bet_title,
    difficultyLabel: item.bet_difficulty[0].label,
    difficulty: item.bet_difficulty[0].difficulty,
    payout: item.bet_payout,
    description:
      typeof item.bet_description === "string"
        ? item.bet_description
        : DOMPurify.sanitize(
            storyblokClient.richTextResolver.render(
              replacePlaceholderValue(item.bet_description, bettingType)
            )
          )
  }));

interface Props {
  betCategories: ContentBetTypes[] | [];
  hash?: string;
  bettingType?: string[];
}

const renderBetTypes = (betTypesList: BetTypeItem[]) =>
  betTypesList.map((betType) => (
    <BetTypeInfo
      key={`betType-${betType.title.replace(" ", "")}`}
      betType={betType}
    />
  ));

export const BetTutorial = (props: Props) => {
  const { betCategories, hash, bettingType } = props;

  const renderBetCategories = () =>
    betCategories.map((betTypeCategory) => {
      if (`#${betTypeCategory.hash}` === hash || !hash) {
        const betTypeFormatted = formatBetType(betTypeCategory, bettingType);
        if (bettingType) {
          const bettingTypeArray = Object.values(bettingType ?? []);
          const betTypes = betTypeFormatted.filter((betType) =>
            bettingTypeArray.includes(betType.title)
          );

          return (
            <React.Fragment
              key={`betCategory-${betTypeCategory.title.replace(" ", "")}`}
            >
              {renderBetTypes(betTypes)}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment
            key={`betCategory-${betTypeCategory.title.replace(" ", "")}`}
          >
            <BetCategoryTitle
              data-qa-label={`betCategoryTitle-${betTypeCategory.title.replace(
                / /g,
                ""
              )}`}
            >
              {betTypeCategory.title.toUpperCase()}
            </BetCategoryTitle>
            {renderBetTypes(betTypeFormatted)}
          </React.Fragment>
        );
      }
      return null;
    });

  return <>{renderBetCategories()}</>;
};

export default BetTutorial;

import styled from "styled-components";

export const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
  display: flex;
  flex-direction: column;
  margin-top: 0 !important;
  border-radius: var(--fd-radii-border-radius-020);
  max-width: 100%;
`;

import styled from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const NavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--fd-space-space-5);
`;

export const NavDots = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--fd-space-space-1);
`;

export const PageDot = styled.button<{
  isActive?: boolean;
}>`
  width: 8px;
  height: 8px;
  transition: background-color 0.3s linear;
  border-radius: var(--fd-radii-border-radius-circle);
  background-color: ${({ isActive }) =>
    isActive
      ? "var(--fd-colors-content-disabled)"
      : "var(--fd-colors-background-base)"};
  cursor: pointer;
`;

export const Image = styled.img`
  height: 300px;
  object-fit: contain;
`;

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and ${breakpoints.tablet.max.sm} {
    flex-grow: 1;

    & > div:first-of-type {
      flex: 1;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;

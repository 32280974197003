import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import Icon from "@fdr/static-ui/Icons";
import { arrowRight } from "@fdr/static-ui/Icons/icons";
import {
  Paragraph,
  useTheme,
  Button,
  useMediaQuery,
  breakpoints
} from "@tvg/design-system";
import { Carousel } from "@tvg/design-system/web";
import { EVENTS } from "@urp/amplitude/src/modules/talentPicks/constants";
import TalentPicksCard from "../TalentPicksCard";
import CardsLoading from "../CardsLoading";
import {
  TalentPicksContainer,
  ContentContainer,
  LinkContainer,
  IconContainer,
  AllTalentPicksWrapper
} from "./styled-components";
import TalentPicksPgP from "./TalentPicksPgP";
import useTalentPicksWithFilters from "../../hooks/useTalentPicksWithFilters";
import { triggerAmplitudeEvent } from "../../utils/amplitude";
import { getTalentPicksListSize } from "../../selectors";

interface SeeAllLinkProps {
  url: string;
  label?: string;
}

interface TalentPickListProps {
  qaLabel?: string;
  onHomePage?: boolean;
  moduleRef?: React.MutableRefObject<HTMLSpanElement>;
  title: string;
  name?: string;
  seeAllLink?: SeeAllLinkProps;
}

const handleSeeAll = () => {
  triggerAmplitudeEvent(EVENTS.CTA_CLICKED);
};

const TalentPickList: FC<TalentPickListProps> = ({
  qaLabel = "talentPicks",
  onHomePage = false,
  moduleRef,
  title
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDesktop = useMediaQuery(breakpoints.tablet.min.sm);
  const { numTPCardstoShowInHP } = useSelector(getTalentPicksListSize);
  const { loading, talentPicksActive } = useTalentPicksWithFilters(onHomePage);
  const hasPicksActive = talentPicksActive.length > 0;

  useEffect(() => {
    const moduleOnHome = moduleRef?.current;
    if (moduleOnHome) {
      moduleOnHome.hidden = !hasPicksActive;
    }
  }, [talentPicksActive]);

  const renderAllLink = () => (
    <LinkContainer theme={theme} data-qa-label="seAllPicksLinkContainer">
      <Link
        to="/handicapping/talent-picks"
        data-qa-label="seAllPicksLinkContainerLink"
        onClick={handleSeeAll}
      >
        <AllTalentPicksWrapper>
          <IconContainer
            theme={theme}
            data-qa-label="seAllPicksLinkContainerIconContainer"
          >
            <Icon
              icon={arrowRight}
              color={theme.colors.blue_accent["600"]}
              size={24}
            />
          </IconContainer>
          <Paragraph
            qaLabel="seeAllPicksLabel"
            fontFamily="bold"
            fontSize={13}
            color={theme.colors.blue_accent[600]}
          >
            See all Picks
          </Paragraph>
        </AllTalentPicksWrapper>
      </Link>
    </LinkContainer>
  );

  const renderItems = () => {
    const items = talentPicksActive
      .filter(
        (_, index) => !numTPCardstoShowInHP || index < numTPCardstoShowInHP
      )
      .map((talentPick, i) => (
        <TalentPicksCard
          key={talentPick.id}
          cardPosition={i}
          talentPick={talentPick}
          showAddToBetslipButton={
            talentPick.raceStatus !== RaceStatusEnum.RACE_OFF
          }
          isFDTVPage
        />
      ));

    return isDesktop ? items : [...items, renderAllLink()];
  };

  if (!hasPicksActive) {
    return null;
  }

  return (
    <TalentPicksContainer theme={theme} data-qa-label={qaLabel}>
      <ContentContainer theme={theme}>
        {loading ? (
          <CardsLoading />
        ) : (
          <>
            {hasPicksActive ? (
              <Carousel
                title={title || "Talent Picks"}
                gap="--fd-space-space-4"
                qaLabel={`${qaLabel}-cards-active`}
                slidesToScroll="auto"
                slideSize={330}
                headerChildren={
                  <Button
                    qaLabel="seeAllPicksLabel"
                    onPress={() => navigate("/handicapping/talent-picks")}
                    variant="tertiary"
                    size="m"
                  >
                    See all
                  </Button>
                }
                displayNavButtons={isDesktop}
                displayHeader={isDesktop}
                items={renderItems()}
              >
                {renderAllLink()}
              </Carousel>
            ) : null}
          </>
        )}
      </ContentContainer>
    </TalentPicksContainer>
  );
};

export { TalentPicksPgP };
export default TalentPickList;

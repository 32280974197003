import styled from "styled-components";
import buildColor from "@fdr/static-ui/ColorPalette";
import { easeInOut, generateTransition } from "@fdr/static-ui/Transitions";
import ButtonDefault from "../Button";

const TabAtom = styled(ButtonDefault)`
  background: none;
  text-align: center;
  transition: ${generateTransition(easeInOut, "color")};
  color: ${buildColor("blue", "500")};
  border-radius: 0;
  white-space: nowrap;
  border: none;
  height: 48px;
  width: 100%;
  padding: 0 8px;
  position: relative;
  overflow: hidden;
  will-change: color;

  &:active:not(:disabled) {
    background-color: ${buildColor("blue", "000")};
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: ${(props) => (props.isActive ? "0" : "-3px")};
    height: 3px;
    will-change: bottom;
    transition: ${generateTransition(easeInOut, "bottom")};
    background-color: ${buildColor("red", "500")};
  }
`;

export default TabAtom;

import React, { useState, useRef, useEffect, useCallback } from "react";
import { Paragraph } from "@tvg/design-system/web";
import { breakpoints, Icon, useMediaQuery } from "@tvg/design-system";
import {
  Container,
  PlayerWrapper,
  CloseButton,
  Content,
  Footer
} from "./styled-components";
import JWPlayer, { Player } from "../../JWPlayer";
import { JWPlayerAPITracks } from "../../../types";
import { VideoPlayerDetailsProps } from "./types";
import Description from "./Description";

const VideoPlayerDetails = ({
  isModalOpen = false,
  src,
  library,
  description,
  tracks,
  isAutoPlay = false,
  renderCaptionsNatively = false,
  allowFullscreen = false,
  qaLabel = "video-details",
  onClose,
  height = 468,
  width = 833,
  onPause,
  onPlay,
  onVideoViewed,
  onVideoPlayedCompletely,
  onVideoPlayAttemptFailed,
  onSetupError,
  onVideoMuteAndUnmute,
  onVideoFullScreen,
  deviceLockRotationToggle
}: VideoPlayerDetailsProps) => {
  const isMobile = useMediaQuery(breakpoints.tablet.max.sm);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [videoPlayer, setVideoPlayer] = useState<Player>();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const attachEvents = (vPlayer: Player) => {
    vPlayer.on("pause", () => onPause && onPause());
    vPlayer.on("play", (e) => onPlay && onPlay(!!e.viewable));
    vPlayer.on(
      "complete",
      () => onVideoPlayedCompletely && onVideoPlayedCompletely()
    );
    vPlayer.on(
      "error",
      (e) => onVideoPlayAttemptFailed && onVideoPlayAttemptFailed(e.message)
    );
    vPlayer.on("setupError", (e) => onSetupError && onSetupError(e.message));
    vPlayer.on("ready", () => onVideoViewed && onVideoViewed());
    vPlayer.on(
      "mute",
      (mute) => onVideoMuteAndUnmute && onVideoMuteAndUnmute(!!mute.mute)
    );
    vPlayer.on("fullscreen", (fullscreen) => {
      setIsFullscreen(!!fullscreen.fullscreen);

      if (onVideoFullScreen) {
        onVideoFullScreen(!!fullscreen.fullscreen);
      }
    });
  };

  const detachEvents = (vPlayer: Player) => {
    vPlayer.off("pause", () => onPause && onPause());
    vPlayer.off("play", (e) => onPlay && onPlay(!!e.viewable));
    vPlayer.off(
      "complete",
      () => onVideoPlayedCompletely && onVideoPlayedCompletely()
    );
    vPlayer.off(
      "error",
      (e) => onVideoPlayAttemptFailed && onVideoPlayAttemptFailed(e.message)
    );
    vPlayer.off("setupError", (e) => onSetupError && onSetupError(e.message));
    vPlayer.off("ready", () => onVideoViewed && onVideoViewed());
    vPlayer.off(
      "mute",
      (mute) => onVideoMuteAndUnmute && onVideoMuteAndUnmute(!!mute)
    );
    vPlayer.off(
      "fullscreen",
      (fullscreen) => onVideoFullScreen && onVideoFullScreen(!!fullscreen)
    );
  };

  useEffect(() => {
    if (videoPlayer) {
      attachEvents(videoPlayer);
    }

    return () => {
      if (videoPlayer) detachEvents(videoPlayer);
    };
  }, [videoPlayer]);

  useEffect(() => {
    // Delay rendering the video until the modal's animation completes,
    // ensuring the control bar layout initializes correctly
    if (isModalOpen) {
      setTimeout(() => {
        setShowVideo(true);
      }, 400);
    }
  }, [isModalOpen]);

  const resizeVideoToWrapper = useCallback(() => {
    if (wrapperRef.current && videoPlayer) {
      const { width: wrapperWidth, height: wrapperHeight } =
        wrapperRef.current.getBoundingClientRect();

      videoPlayer.resize(wrapperWidth, wrapperHeight);
    }
  }, [wrapperRef, videoPlayer]);

  useEffect(() => {
    if (isMobile) {
      resizeVideoToWrapper();
    }
  }, [wrapperRef, videoPlayer, isFullscreen, isMobile]);

  const orientationChange = useCallback(() => {
    const isLandscape = !window.matchMedia("(orientation: portrait)").matches;
    if (videoPlayer?.getState() === "playing") {
      setIsFullscreen(isLandscape);
      videoPlayer?.setFullscreen(isLandscape);
    }
    resizeVideoToWrapper();
  }, [videoPlayer, setIsFullscreen, resizeVideoToWrapper]);

  useEffect(() => {
    if (isMobile) {
      if (window) {
        deviceLockRotationToggle(false);
        // Only to clean the listener if already exists
        window.removeEventListener("resize", orientationChange);
        window.addEventListener("resize", orientationChange);
      }

      return () => {
        window.removeEventListener("resize", orientationChange);
        deviceLockRotationToggle(true);
      };
    }
    return () => null;
  }, [videoPlayer]);

  return (
    <Container data-qa-label={qaLabel}>
      <PlayerWrapper
        ref={wrapperRef}
        data-qa-label={`${qaLabel}-player-wrapper`}
        height={height}
      >
        {onClose && (
          <CloseButton data-qa-label={`${qaLabel}-close`} onClick={onClose}>
            <Icon name="close" size="m" lineColor="white.900" />
          </CloseButton>
        )}
        {showVideo && src && library && (
          <JWPlayer
            src={src}
            tracks={tracks as unknown as JWPlayerAPITracks[]}
            library={library}
            isAutoPlay={isAutoPlay}
            renderCaptionsNatively={renderCaptionsNatively}
            allowFullscreen={allowFullscreen}
            didMountCallback={({ player }) => setVideoPlayer(player)}
            height={height}
            width={width}
          />
        )}
      </PlayerWrapper>
      {description && !isFullscreen && (
        <Content>
          <Description
            qaLabel={qaLabel}
            description={description}
            onAnimationEnd={resizeVideoToWrapper}
          />
          <Footer>
            <Paragraph
              fontFamily="condensedRegular"
              fontSize="xs"
              textTransform="uppercase"
              color="--fd-colors-content-subtle"
              qaLabel={`${qaLabel}-category`}
            >
              {description.category}
            </Paragraph>

            <Paragraph
              fontFamily="condensedRegular"
              fontSize="xs"
              textTransform="uppercase"
              color="--fd-colors-content-subtle"
              qaLabel={`${qaLabel}-timestamp`}
            >
              {description.uploadedAt}
            </Paragraph>
          </Footer>
        </Content>
      )}
    </Container>
  );
};

export default VideoPlayerDetails;

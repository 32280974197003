import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import StoryblokClient from "storyblok-js-client";
import tvgConf from "@tvg/conf";
import TabGroup from "@fdr/atomic-ui/_molecules/TabGroup";
import RichText from "@fdr/atomic-ui/_atoms/RichText";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import mediator from "@tvg/mediator";
import { Containers } from "@tvg/atomic-ui/_static/BaseComponents";
import FrequentlyAskedQuestions from "@fdr/atomic-ui/_molecules/FrequentlyAskedQuestions";
import EducationalHubTemplate from "@fdr/atomic-ui/_templates/EducationalHub";
import ComponentSection from "@fdr/atomic-ui/_organisms/ComponentSection";
import BetTutorial from "@fdr/atomic-ui/_templates/BetTutorial";
import { get } from "lodash";
import { setSelectedSlug } from "./redux/actions";
import { formatFaqList } from "./utils";
import useContentData from "./hooks/useContentData";
import {
  InternalModalProps,
  ContentFaqItem,
  ContentRichText,
  ContentBetTypes
} from "./types";
import { ReferenceWrapper, ContentWrapper } from "./styled-components";

const storyblokToken = tvgConf().config("storyblokToken");

const storyblokClient = new StoryblokClient({
  accessToken: storyblokToken,
  cache: {
    clear: "auto",
    type: "memory"
  }
});

const selectTabGTM = (itemName: string) => {
  mediator.base.dispatch({
    type: "EDUCATION_PILL_CLICKED",
    payload: {
      eventLabel: itemName
    }
  });
};

const BaseInternalModal = ({
  contentSlug,
  isOpen,
  onClose,
  clearHash
}: InternalModalProps) => {
  const dispatch = useDispatch();
  const selectedSlug = useSelector((store) =>
    get(store, "educationalHub.selectedSlug", "")
  );
  const storedSlugContent = useSelector((store) =>
    get(store, `educationalHub.storedSlugsData.${selectedSlug}`)
  );
  const { contentData, isLoading } = useContentData(
    selectedSlug,
    dispatch,
    storedSlugContent
  );
  const sectionsReferences = useRef<HTMLElement[]>([]);
  const [selectedTab, setSelectedTab] = useState<string>();
  const [internalHash, setInternalHash] = useState<string>();
  let observer = undefined as IntersectionObserver | undefined;
  useEffect(() => {
    setInternalHash(window.location.hash);
    if (!isOpen) sectionsReferences.current = [];
  }, [isOpen]);

  useEffect(() => {
    dispatch(setSelectedSlug(contentSlug));
  }, [contentSlug]);

  const handleTabClick = (newTab: string, position: number) => {
    const scrollProps: ScrollIntoViewOptions = {
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    };

    if (
      sectionsReferences?.current &&
      sectionsReferences.current[position] &&
      tabs.length > 1
    ) {
      (sectionsReferences.current[position] as HTMLElement).scrollIntoView(
        scrollProps
      );
      if (tabs.length < 3) {
        setSelectedTab(newTab);
      }
    }
  };
  const attachReference = (el: HTMLElement | null) => {
    if (sectionsReferences.current.length === tabs.length) {
      sectionsReferences.current = [];
    }
    if (
      !!el &&
      !sectionsReferences.current.includes(el) &&
      tabs.length > 1 &&
      sectionsReferences.current.length !== tabs.length
    ) {
      sectionsReferences.current[sectionsReferences.current.length] = el;
    }
  };

  let tabs = useMemo(
    () =>
      contentData?.sections.map((tabItem, index) => ({
        type: "subTab",
        title: tabItem.tab_name,
        isActive: selectedTab === tabItem.tab_name,
        onClick: () => {
          selectTabGTM(tabItem.tab_name);
          handleTabClick(tabItem.tab_name, index);
        }
      })) || [],
    [contentData, selectedTab, isOpen]
  );

  useEffect(() => {
    if (
      (!!selectedTab && !tabs.map((tab) => tab.title).includes(selectedTab)) ||
      (!selectedTab && tabs.length)
    ) {
      setSelectedTab(tabs[0].title);
    }
  }, [tabs]);

  useEffect(() => {
    const windowInnerHeight = window && window.innerHeight;
    if (!isLoading) {
      if (observer) {
        observer.disconnect();
      }

      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            let extractedTab;
            if (
              !entry.isIntersecting &&
              entry.boundingClientRect.bottom < windowInnerHeight
            ) {
              extractedTab =
                tabs[
                  sectionsReferences.current.findIndex(
                    (el) => el === entry.target.nextElementSibling
                  )
                ];
            }
            if (entry.isIntersecting) {
              extractedTab =
                tabs[
                  sectionsReferences.current.findIndex(
                    (el) => el === entry.target
                  )
                ];
            }
            if (extractedTab?.title && sectionsReferences.current.length > 2) {
              setSelectedTab(extractedTab.title);
            }
          });
        },
        { threshold: 0.3 }
      );
      if (
        isOpen &&
        sectionsReferences.current.length > 2 &&
        sectionsReferences.current.length === tabs.length
      ) {
        setTimeout(() => {
          sectionsReferences.current.forEach(
            (el) => observer && observer.observe(el)
          );
        }, 800);
      } else {
        observer.disconnect();
      }
    }
    return () => {
      sectionsReferences.current.forEach(
        (el) => observer && observer.unobserve(el)
      );
    };
  }, [sectionsReferences.current.length, isLoading, isOpen, internalHash]);

  const onCloseDecorated = (): void => {
    if (onClose) onClose();
  };

  const handleOnFooterClick = () => {
    const cachedUrl = contentData?.footer_link?.cached_url;
    const slug = cachedUrl ? cachedUrl.split("hub/")[1] : "";
    clearHash();
    setInternalHash("");
    dispatch(setSelectedSlug(slug));
    sectionsReferences.current = [];
  };
  return (
    <ModalV2
      title={contentData?.title}
      animation="bottom"
      isOpen={isOpen}
      onClose={onCloseDecorated}
      isFullHeight={false}
    >
      {() => (
        <Containers.Page>
          {tabs.length > 1 && !internalHash && !isLoading && (
            <TabGroup
              tabs={tabs}
              hasBottomBorder
              stretch={false}
              isSticky
              noTopSpace
            />
          )}
          <EducationalHubTemplate
            isLoading={isLoading}
            hasFooter={contentData?.has_footer || !!internalHash}
            footerTitle={contentData?.footer_title}
            onFooterClick={handleOnFooterClick}
          >
            {contentData?.sections.map((section) => (
              <ContentWrapper
                /* eslint-disable-next-line no-underscore-dangle */
                key={`educationalHub-section-${section._uid}`}
              >
                {section.type === "bet_types" && (
                  <ReferenceWrapper
                    key={section.type}
                    disableTopBorder={contentData?.sections.length > 1}
                    ref={(el) => attachReference(el)}
                    isModal
                  >
                    <ComponentSection title={section.title} noTopPadding>
                      <BetTutorial
                        betCategories={
                          (section?.content as ContentBetTypes[]) || []
                        }
                        hash={internalHash}
                      />
                    </ComponentSection>
                  </ReferenceWrapper>
                )}

                {section.type === "faq" && !internalHash && (
                  <ReferenceWrapper
                    disableTopBorder={contentData?.sections.length > 1}
                    key={section.type}
                    ref={(el) => attachReference(el)}
                    isModal
                  >
                    <ComponentSection title={section.title}>
                      <FrequentlyAskedQuestions
                        faqList={formatFaqList(
                          section.content as ContentFaqItem[],
                          storyblokClient
                        )}
                      />
                    </ComponentSection>
                  </ReferenceWrapper>
                )}

                {section.type === "richtext" && !internalHash && (
                  <ReferenceWrapper
                    disableTopBorder={contentData?.sections.length > 1}
                    key={section.type}
                    ref={(el) => attachReference(el)}
                    isModal
                  >
                    <ComponentSection title={section.title}>
                      <RichText
                        hasPadding
                        text={storyblokClient.richTextResolver.render(
                          (section.content[0] as ContentRichText).text
                        )}
                      />
                    </ComponentSection>
                  </ReferenceWrapper>
                )}
              </ContentWrapper>
            ))}
          </EducationalHubTemplate>
        </Containers.Page>
      )}
    </ModalV2>
  );
};

export default BaseInternalModal;

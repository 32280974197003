import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";

export const ScrollableContainer = styled.div`
  background-color: var(--fd-colors-background-surface);
  width: 100%;
  display: flex;
  gap: var(--fd-space-space-2);
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-padding-left: var(--fd-space-space-2);

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled.div<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      background-color: var(--fd-colors-background-layer);
    `};
`;

export const LoadingContainer = styled.div`
  background-color: var(--fd-colors-background-surface);

  @media screen and ${breakpoints.tablet.min.sm} {
    padding: var(--fd-space-space-4);
  }
`;

export const SectionItems = styled.div`
  display: flex;
  overflow: scroll;
  scroll-snap-type: x mandatory;
  margin: 0 var(--fd-space-space-4);
  padding-bottom: var(--fd-space-space-2);
  gap: var(--fd-space-space-2);

  & ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 6px;
  }

  & ::-webkit-scrollbar-thumb {
    border-radius: var(--fd-radii-border-radius-020);
    background-color: var(--fd-colors-content-subtle);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--fd-space-space-4);
`;

export const Element = styled.div`
  scroll-snap-align: start;

  @media screen and ${breakpoints.tablet.max.sm} {
    &:first-of-type {
      padding-left: var(--fd-space-space-4);
    }

    &:last-of-type {
      padding-right: var(--fd-space-space-4);
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
`;

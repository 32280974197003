import styled, { css } from "styled-components";
import { buildColor, breakpoints } from "@tvg/design-system";

const badgeSize = css`
  width: 40px;
  height: 40px;
`;

const badgeBorder = css`
  outline: ${({ theme }) => `2px solid ${theme.colorTokens.background.layer}`};
`;

export const Container = styled.div`
  @media screen and ${breakpoints.tablet.max.sm} {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-padding-left: ${({ theme }) => theme.space["space-4"]};
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const FilterSelectorWrapper = styled.button<{
  hasSelected: boolean;
  hideButton: boolean;
  isActive: boolean;
}>`
  width: 72px;
  height: 86px;
  outline: none;
  background-color: ${({ theme, isActive }) =>
    isActive
      ? theme.colorTokens.component.button.secondaryDark.background.base
      : theme.colorTokens.background.promotional};
  display: ${({ hideButton }) => (hideButton ? "none;" : "flex;")};
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  padding: ${({ theme }) => theme.space["space-1"]};

  &:hover {
    cursor: pointer;
  }
`;

export const BadgeWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: var(--fd-space-space-3);
  margin-bottom: 6px;

  & div[aria-label*="notifications"] {
    right: 3px;
  }
`;

export const TalentImage = styled.img<{ isActive: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${buildColor("grey", "300")};
  object-fit: cover;
  ${({ isActive }) => isActive && badgeBorder}
`;

export const AllTalents = styled.div<{ isActive: boolean }>`
  align-items: center;
  background-color: ${buildColor("blue", "500")};
  border-radius: 50%;
  color: ${buildColor("white", "900")};
  display: flex;
  justify-content: center;
  ${badgeSize}
  ${({ isActive }) => isActive && badgeBorder}
`;

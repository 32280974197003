import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 90%);
  border-radius: var(--fd-radii-border-radius-circle);
  overflow: hidden;
`;

const SVG = styled.svg`
  display: flex;
  flex-direction: column;
`;

const PlayIcon = () => (
  <Container>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.64735 18.8236C2.39735 18.8236 2.14735 18.7706 1.92382 18.6622C1.46205 18.4379 1.17676 18.0168 1.17676 17.5631V2.43678C1.17676 1.98552 1.46205 1.5645 1.92382 1.34013C2.38264 1.11828 2.95029 1.1208 3.40323 1.35526L18.1091 8.92091C18.5532 9.15033 18.8238 9.55873 18.8238 10.0024C18.8238 10.4461 18.5532 10.8571 18.1091 11.084L3.40323 18.6446C3.17088 18.7656 2.90911 18.8236 2.64735 18.8236Z"
        fill="#1493ff" // TODO: missing formation color token
      />
    </SVG>
  </Container>
);

export default PlayIcon;

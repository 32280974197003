import styled, { css } from "styled-components";

import Icon from "../../_static/Icons";
import color from "../../_static/ColorPalette";
import { titleMedium, fontBold } from "../../_static/Typography";

/* to be refactored to use colors object */
const buildLabel = (tag) => {
  let tagStyles;

  switch (tag) {
    case "live":
      tagStyles = css`
        background-color: ${color("red", "500")};
        color: ${color("white", "100")};
      `;
      break;
    case "new":
      tagStyles = css`
        background-color: ${color("yellow", "500")};
        color: ${color("grey", "900")};
      `;
      break;

    default:
      tagStyles = null;
  }

  return tagStyles;
};

export const ExpandedLink = styled.a`
  color: ${color("white", "100")};
  font-size: 14px;
  font-family: ${titleMedium};
  text-decoration: none;
  flex: 0 0 auto;
`;

export const NonRouterLink = styled.a`
  display: flex;
  width: 100%;
  align-items: center;
  flex: 0 0 auto;
  padding: 0 8px;
  color: ${color("white", "100")};
  font-size: 14px;
  font-family: ${titleMedium};
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  ${({ tag }) =>
    !!tag &&
    css`
      &::after {
        content: "${tag}";
        padding: 1px 3px;
        margin-left: 4px;
        font-size: 12px;
        border-radius: 2px;
        ${buildLabel(tag)};
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: capitalize;
      }
    `};

  :hover {
    text-decoration: none;
    color: ${color("white", "100")};
  }
`;

export const RouterLinkContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex: 0 0 auto;
  ${({ expanded }) =>
    !expanded
      ? css`
          padding: 0 8px;
        `
      : css`
          flex-direction: column;
        `}
  color: ${color("white", "100")};
  font-size: 14px;
  font-family: ${titleMedium};
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  ${({ tag, expanded }) =>
    !!tag && !expanded
      ? css`
          &::after {
            content: "${tag}";
            padding: 1px 3px;
            margin-left: 4px;
            font-size: 12px;
            border-radius: 2px;
            ${buildLabel(tag)};
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: capitalize;
          }
        `
      : css`
          &::after {
            content: "${tag}";
            padding: 1px 3px;
            font-size: 12px;
            border-radius: 8px;
            width: 31px;
            ${buildLabel(tag)};
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 13px;
            right: 13px;
            z-index: 999;
            text-transform: capitalize;
          }
        `};
`;

export const LinkIcon = styled(Icon)`
  margin-right: ${(props) => (props.isVerticalLayout ? "8px" : "4px")};
`;

export const ExpandedInnerWrapper = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ device }) =>
    device === "mobile"
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100% - 8px);
        `}
  height: ${({ device }) => (device === "desktop" ? "152px" : "109px")};
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  &:hover {
    cursor: pointer;

    & > a > div > div:last-child {
      background-color: ${color("blue_accent", "600")};
    }
  }

  & > a {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

export const LabelWrapper = styled.div`
  background-color: ${color("blue_accent", "700")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  ${({ device }) =>
    device === "desktop"
      ? css`
          height: 40px;
          padding: 12px;
        `
      : css`
          height: 32px;
          padding: 12px;
        `}
  width: 100%;
  z-index: 200;
`;

export const ExpandedImg = styled.div`
  ${({ src, zIndex }) => css`
    background-image: url(${src});
    z-index: ${zIndex};
  `};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${({ device }) => (device !== "desktop" ? "88px" : "100%")};

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 90;
    background: ${({ backgroundColor }) =>
      `linear-gradient(rgb(0 0 0 / 0%), ${backgroundColor} 95%)`};
  }
`;

export const LabelTextWrapper = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: ${fontBold};
  font-size: 14px;
  line-height: 16px;
  ${({ device }) =>
    device === "desktop"
      ? css`
          max-width: calc(100% - 16px);
        `
      : device === "mobile" &&
        css`
          font-size: 12px;
        `}
`;

import styled from "styled-components";

export const VideoCardButton = styled.button`
  overflow: hidden;
  min-width: 292px;
  border-radius: var(--fd-radii-border-radius-030);
  background: var(--fd-colors-background-surface);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
`;

export const Duration = styled.span`
  padding: 1px var(--fd-space-space-1) 0 var(--fd-space-space-1);
  border-radius: var(--fd-radii-border-radius-010);
  background: var(--fd-colors-background-secondary);
  color: var(--fd-colors-content-on-dark);
  font-size: var(--fd-font-sizes-font-size-030);
  line-height: 125%;
  place-self: end start;
`;

export const Thumbnail = styled.div<{ source: string }>`
  position: relative;
  height: 168px;
  background: ${({ source }) =>
    `url(${source}) lightgray 50% / cover no-repeat`};
  padding: var(--fd-space-space-5);
  display: grid;
`;

export const IconContainer = styled.div`
  position: absolute;
  place-self: center center;
`;

export const Description = styled.div`
  padding: var(--fd-space-space-3);
  height: 87px;
  display: flex;
  text-align: start;
  justify-content: space-between;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  grid-template-columns: 1fr auto;
  gap: var(--fd-space-space-3);
`;

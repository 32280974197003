import { TRACKS_FILTER_OPTIONS } from "@urp/lib-racetracks/src/constants";
import { PastResultsSearchOptionKey } from "./types";
import { SearchConfig } from "./components/PastResults/types";

export const INTERNAL_DATE_FORMAT = "yyyy-MM-dd";
export const DISPLAY_DATE_FORMAT = "MM/dd/yyyy";

export const pastResultsInitialConfig: SearchConfig = {
  label: "SEARCH BY",
  type: "tabs",
  options: [
    {
      key: PastResultsSearchOptionKey.DATE,
      label: "Date",
      fields: [
        {
          key: "date",
          label: "Date",
          type: "date-picker",
          value: ""
        },
        {
          key: "track",
          label: "Track",
          type: "dropdown",
          options: [TRACKS_FILTER_OPTIONS.NO_TRACKS],
          value: "allTracks"
        }
      ]
    },
    {
      key: PastResultsSearchOptionKey.HORSE,
      label: "Horse",
      fields: [
        {
          key: "horse",
          label: "Horse",
          type: "search",
          value: "",
          placeholder: "Search by Horse",
          warningMessages: {
            minLength: "Please enter at least 3 characters",
            notFound: "No results found"
          }
        }
      ]
    },
    {
      key: PastResultsSearchOptionKey.TRACK,
      label: "Track",
      fields: [
        {
          key: "track",
          label: "Track",
          type: "dropdown",
          options: [TRACKS_FILTER_OPTIONS.NO_TRACKS],
          value: "allTracks"
        },
        {
          key: "date",
          label: "Date",
          type: "date-picker",
          value: ""
        }
      ]
    }
  ],
  selected: "date"
};

export const MAX_RUNNERS_RESULTS = 4;

export enum PAYOUT_TYPES {
  WIN = "Win",
  PLACE = "Place",
  SHOW = "Show"
}

export const WARNING_TOP_POSITION_OFFSET_MOBILE = 137;

export const WARNING_TOP_POSITION_OFFSET_DESKTOP = 40;

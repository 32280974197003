import { groupBy } from "lodash";
import { Track } from "@tvg/ts-types/Track";
import {
  FilterOptions,
  TrackFilterValueEnum
} from "@urp/lib-racetracks/src/types";
import { RaceTypeCodeEnum } from "@tvg/ts-types/Race";
import { Runner } from "@tvg/design-system/web/components/RaceResult/types";
import { ResultFieldType } from "../components/ResultsSearchForm/types";
import { PAYOUT_TYPES } from "../constants";
import { SupportedFilters } from "../types";

export const getRaceTracksByLetter = (races: Track[]) =>
  groupBy(races, (item) => item.name[0]);

export const getWidthCells = (
  lastStep: number,
  childNodes: NodeListOf<ChildNode> | undefined,
  offset: number
) => {
  let totalWidth = 0;
  if (childNodes) {
    childNodes.forEach((node, idx) => {
      const elem = node as HTMLElement;
      if (idx < lastStep) totalWidth += elem.scrollWidth + offset;
    });
  }

  return totalWidth;
};

/**
 * Counts the number of elements that fit in the width of the parent
 *
 * @returns Number of visible elements
 */
export const calculateVisibleElements = (
  container: HTMLDivElement | null,
  navigationStep = 1
) => {
  const scrollWidth = container?.clientWidth ?? 0;
  let elementsToNavigate = 0;
  let accWidth = 0;
  container?.childNodes.forEach((el) => {
    accWidth += +getComputedStyle(el as Element).width.replace("px", "");

    if (accWidth < scrollWidth) elementsToNavigate += navigationStep;
  });

  return elementsToNavigate;
};

export const countryToEmojiFlag = (countryCode: string) => {
  const exceptionList = ["ARE", "DNK", "IRE", "SWE"];

  const country = exceptionList.includes(countryCode)
    ? countryCode.charAt(0).toUpperCase() +
      countryCode.charAt(countryCode.length - 1).toUpperCase()
    : countryCode;

  const codePoints = country
    .substring(0, 2)
    .toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const getTrackLocation = (content: string) => {
  // TODO: refactor using regex later
  let address = "";
  const indexOfAddress = content.search("Address");
  address = indexOfAddress !== -1 ? content.substr(indexOfAddress) : "";

  const indexOfParagraphEnd = address.search("</p>");
  address =
    indexOfParagraphEnd !== -1 ? address.substr(0, indexOfParagraphEnd) : "";

  const indexOfHeadingEnd = address.search("</h2>");
  address =
    indexOfHeadingEnd !== -1 ? address.substr(indexOfHeadingEnd + 5) : "";

  address = address.replace(/<.*>/g, "");

  return address.trim();
};
export const isFilterApplied = (
  filterOptions: FilterOptions | undefined
): boolean => {
  if (filterOptions) {
    const {
      raceTypesFilter,
      regionsFilter,
      distancesFilter,
      racesFilter,
      trackTypesFilter
    } = filterOptions;
    return !!(
      raceTypesFilter?.length ||
      regionsFilter?.length ||
      distancesFilter?.length ||
      racesFilter?.length ||
      trackTypesFilter?.length
    );
  }
  return false;
};

export const getPayoutHeaders = (runners: Runner[], size: number) =>
  runners.slice(0, size).reduce((acc: string[], runner: Runner) => {
    if (runner.win && !acc.includes(PAYOUT_TYPES.WIN))
      return [...acc, PAYOUT_TYPES.WIN];
    if (runner.place && !acc.includes(PAYOUT_TYPES.PLACE))
      return [...acc, PAYOUT_TYPES.PLACE];
    if (runner.show && !acc.includes(PAYOUT_TYPES.SHOW))
      return [...acc, PAYOUT_TYPES.SHOW];
    return acc;
  }, []);

export const getFiltersApplied = (
  raceTypes: RaceTypeCodeEnum[],
  regionsFilter: string[],
  trackTypesFilter: TrackFilterValueEnum[],
  racesFilter: string[],
  distancesFilter: string[]
) => {
  const filterName = [];
  const filterValue = [];
  if (raceTypes.length) {
    filterName.push(SupportedFilters.RACE_TYPE.toLocaleLowerCase());
    filterValue.push(raceTypes.join(","));
  }

  if (regionsFilter.length) {
    filterName.push(SupportedFilters.REGION.toLocaleLowerCase());
    filterValue.push(
      regionsFilter.map((val) => val.toLocaleLowerCase()).join(",")
    );
  }

  if (trackTypesFilter.length) {
    filterName.push(SupportedFilters.BETTING_FEATURE.toLocaleLowerCase());
    filterValue.push(
      trackTypesFilter.map((val) => val.toLocaleLowerCase()).join(",")
    );
  }

  if (racesFilter.length) {
    filterName.push(SupportedFilters.TRACK_TYPE.toLocaleLowerCase());
    filterValue.push(
      racesFilter.map((val) => val.toLocaleLowerCase()).join(",")
    );
  }

  if (distancesFilter.length) {
    filterName.push(SupportedFilters.DISTANCE.toLocaleLowerCase());
    filterValue.push(
      distancesFilter.map((val) => val.toLocaleLowerCase()).join(",")
    );
  }

  return {
    filterName: filterName.join(","),
    filterValue: filterValue.join(",")
  };
};

export const getFieldType = (
  fieldType: ResultFieldType,
  isMobile: boolean = false
): ResultFieldType =>
  fieldType === "dropdown" && isMobile ? "input-modal" : fieldType;

import { formatSecondsToDuration } from "@tvg/formatter/dates";
import axios, { AxiosResponse } from "axios";
import mediator from "@tvg/mediator";
import get from "lodash/get";
import { formatDistanceToNow, fromUnixTime, getUnixTime } from "date-fns";
import {
  JWVideoPlaylist,
  JwtResponse,
  Playlist,
  VideoNativeRotation
} from "../components/PlaylistVideos/types";

export const UNIT_MS_CONVERSIONS = {
  second: 1000,
  minute: 60000,
  hour: 3600000,
  day: 86400000
};

export const getJWPlaylistVideos = async (
  playlistUrl: string,
  playerScript: string,
  licenseKey: string
): Promise<JWVideoPlaylist> =>
  axios
    .get(playlistUrl)
    .then(({ data }: AxiosResponse<JwtResponse>) => {
      const mappedPlaylist = data.playlist.map<Playlist>((media) => {
        const uploadedAt = formatTimeAgo(
          fromUnixTime(get(media, "pubdate", 0))
        );
        const [highestQualitySource] = media.sources
          .filter((source) => source.type === "video/mp4")
          .sort(
            (a, b) =>
              (b.height ?? 0) * (b.width ?? 0) -
              (a.height ?? 0) * (a.width ?? 0)
          );

        const dimensions = getPlayerDimensions({
          width: highestQualitySource?.width ?? 0,
          height: highestQualitySource?.height ?? 0
        });

        const duration = formatSecondsToDuration(
          get(media, "duration", 0)
        ).substring(0, 5);

        return {
          description: {
            title: get(media, "title", ""),
            description: get(media, "description", ""),
            category: get(media, "category", ""),
            uploadedAt
          },
          video: {
            playerId: get(media, "mediaid"),
            src: highestQualitySource?.file ?? "",
            thumbnail: get(media, "image"),
            tracks: get(media, "tracks", []),
            duration,
            playerScript,
            licenseKey,
            ...dimensions
          }
        };
      });

      return {
        title: data.title,
        raceDate: data.raceDate || "",
        raceId: data.raceId || "",
        playlist: mappedPlaylist
      };
    })
    .catch(() => ({
      title: "",
      raceDate: "",
      raceId: "",
      playlist: []
    }));

export const determineUnitType = (timeDiffMs: number) => {
  const absoluteValue = Math.abs(timeDiffMs);

  if (
    absoluteValue > UNIT_MS_CONVERSIONS.day ||
    absoluteValue > UNIT_MS_CONVERSIONS.hour * 23.5
  ) {
    return "day";
  }

  if (
    absoluteValue > UNIT_MS_CONVERSIONS.hour ||
    absoluteValue > UNIT_MS_CONVERSIONS.minute * 59.5
  ) {
    return "hour";
  }

  if (
    absoluteValue > UNIT_MS_CONVERSIONS.minute ||
    absoluteValue > UNIT_MS_CONVERSIONS.second * 59.5
  ) {
    return "minute";
  }

  return "second";
};

export const formatTimeAgo = (date: Date) => {
  const rtf = new Intl.RelativeTimeFormat("en", {
    numeric: "always"
  });

  const dateInMs = Number(String(getUnixTime(date)).padEnd(13, "0"));
  const timeDifferenceMs = dateInMs - new Date().getTime();

  const unitType = determineUnitType(timeDifferenceMs);
  const convertedUnit = get(UNIT_MS_CONVERSIONS, unitType, 0);
  const division = timeDifferenceMs / convertedUnit;
  const absoluteDivision = Math.abs(division);

  if (unitType === "second") return "just added";

  if (unitType === "day") {
    return absoluteDivision <= 30
      ? formatDistanceToNow(date, { addSuffix: true })
      : "over a month ago";
  }

  return rtf.format(Math.round(division), unitType);
};

export const getPlayerDimensions = ({
  height,
  width
}: {
  height: number;
  width: number;
}) => ({ height: height > width ? 720 : 220, width: 390 });

export const toggleNativeRotation = (locationScreen: VideoNativeRotation) => {
  mediator.ios.dispatch({
    type: "IPHONE_LOCK_SCREEN_ROTATION",
    payload: { lockScreenRotation: locationScreen }
  });
};

import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
  fill: var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05));
`;

const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
`;

const MobileHeaderLoading = () => (
  <Container>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="49"
      viewBox="0 0 375 49"
      fill="none"
      preserveAspectRatio="none"
    >
      <mask id="path-1-inside-1_104_67649" fill="white">
        <path d="M0 0H375V49H0V0Z" />
      </mask>
      <path d="M0 0H375V49H0V0Z" fill="white" />
      <path
        d="M375 48H0V50H375V48Z"
        fill="#C6D3E1"
        mask="url(#path-1-inside-1_104_67649)"
      />
      <rect
        x="12"
        y="12"
        width="24"
        height="24"
        rx="2"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
      />
      <rect
        x="83.2998"
        y="12"
        width="208.4"
        height="24"
        rx="2"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
      />
      <rect
        x="339"
        y="12"
        width="24"
        height="24"
        rx="2"
        fill="var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05))"
      />
    </SVG>
  </Container>
);

export default MobileHeaderLoading;

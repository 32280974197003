import React from "react";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
   opacity: 1
  }

  50% {
    opacity: 0.5
  }

  100% {
    opacity: 1
  }
`;

const SVG = styled.svg`
  animation: ${pulseAnimation} 1.25s infinite;
  fill: var(--fd-colors-content-loading-default, rgba(10, 10, 10, 0.05));
`;
const Container = styled.div`
  background-color: var(--fd-colors-background-surface);
`;

export default function RichTextLoading() {
  return (
    <Container>
      <SVG
        width="100%"
        preserveAspectRatio="none"
        height="808"
        viewBox="0 0 651 808"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_104_59518)">
          <g clipPath="url(#clip1_104_59518)">
            <g clipPath="url(#clip2_104_59518)">
              <rect width="651" height="808" rx="4" fill="white" />
              <rect width="651" height="64" fill="white" />
              <rect width="635" height="64" fill="white" />
              <rect x="16" y="23" width="200" height="18" rx="2" />
              <rect x="16" y="76" width="420.184" height="16" rx="2" />
              <rect x="16" y="100" width="540.24" height="16" rx="2" />
              <rect x="16" y="124" width="484.562" height="16" rx="2" />
              <rect x="16" y="156" width="420.184" height="16" rx="2" />
              <rect x="16" y="180" width="540.24" height="16" rx="2" />
              <rect x="16" y="204" width="484.562" height="16" rx="2" />
              <rect x="16" y="236" width="420.184" height="16" rx="2" />
              <rect x="16" y="260" width="540.24" height="16" rx="2" />
              <rect x="16" y="284" width="484.562" height="16" rx="2" />
              <rect x="16" y="324" width="420.184" height="16" rx="2" />
              <rect x="16" y="348" width="540.24" height="16" rx="2" />
              <rect x="16" y="372" width="484.562" height="16" rx="2" />
              <rect x="16" y="404" width="420.184" height="16" rx="2" />
              <rect x="16" y="428" width="540.24" height="16" rx="2" />
              <rect x="16" y="452" width="484.562" height="16" rx="2" />
              <rect x="16" y="484" width="420.184" height="16" rx="2" />
              <rect x="16" y="508" width="540.24" height="16" rx="2" />
              <rect x="16" y="532" width="484.562" height="16" rx="2" />
              <rect x="16" y="572" width="420.184" height="16" rx="2" />
              <rect x="16" y="596" width="540.24" height="16" rx="2" />
              <rect x="16" y="620" width="484.562" height="16" rx="2" />
              <rect x="16" y="652" width="420.184" height="16" rx="2" />
              <rect x="16" y="676" width="540.24" height="16" rx="2" />
              <rect x="16" y="700" width="484.562" height="16" rx="2" />
              <rect x="16" y="732" width="420.184" height="16" rx="2" />
              <rect x="16" y="756" width="540.24" height="16" rx="2" />
              <rect x="16" y="780" width="484.562" height="16" rx="2" />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_104_59518">
            <rect width="651" height="808" fill="white" />
          </clipPath>
          <clipPath id="clip1_104_59518">
            <rect width="651" height="808" rx="4" fill="white" />
          </clipPath>
          <clipPath id="clip2_104_59518">
            <rect width="651" height="808" rx="4" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
}

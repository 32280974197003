import React from "react";
import color from "@fdr/static-ui/ColorPalette";
import styled, { keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% {
    fill: ${color("grey", "100")};
  }

  50% {
    fill: ${color("grey", "000")};
  }

  100% {
    fill: ${color("grey", "100")};
  }
`;

const Container = styled.div`
  width: 150px;
  height: 8px;
  background-color: white;
  overflow: hidden;
`;

const SVG = styled.svg`
  display: block;

  rect.pulse {
    animation: ${pulseAnimation} 1.25s infinite;
  }
`;

const ComponentSectionHeaderMask = () => (
  <Container>
    <SVG>
      <rect
        width="150"
        height="8"
        fill={color("grey", "100")}
        rx="1"
        className="pulse"
      />
    </SVG>
  </Container>
);

export default ComponentSectionHeaderMask;

import styled from "styled-components";

export const CardsWrapper = styled.div`
  display: flex;
  gap: var(--fd-space-space-2);
  padding: 0 var(--fd-space-space-4) var(--fd-space-space-4);
  overflow: hidden;
`;

export const VideoSectionWrapper = styled.div`
  width: 100%;
  background: var(--fd-colors-background-surface);
`;

export const LatestVideos = styled.div`
  background: var(--fd-colors-background-surface);
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  display: flex;
  gap: var(--fd-space-space-2);
  padding: 0 var(--fd-space-space-4) var(--fd-space-space-4);
  overflow: hidden;
`;

export const CONTAINER = "container";
export const HEADER = "header";
export const RUNNER = "runner";
export const FOOTER = "footer";
export const ICONS = "icons";
export const TAG = "tag";
export const LOCATION = "race-location";
export const FLAG = "flag";
export const TEXT = "text";
export const JOCKEY = "jockey";
export const TRAINER = "trainer";
export const ODDS = "odds";
// render
export const CARD_COUNT_MOBILE = 2;
export const CARD_COUNT_TABLET = 5;
export const CARD_COUNT_DESKTOP = 9;
// apollo options
export const POLL_INTERVAL = 60000;
export const MAX_RESULTS = 9;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const Button = styled.button`
  display: flex;
  flex-direction: column;
  border-radius: var(--fd-radii-border-radius-020);
  border: 1px solid var(--fd-colors-border-default);
  background-color: var(--fd-colors-background-surface);
  overflow: hidden;
  cursor: pointer;
  width: 164px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: var(--fd-space-space-4);
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--fd-space-space-2);
  background-color: var(--fd-colors-background-base);
  padding: var(--fd-space-space-3);
  margin-top: auto;
  width: 100%;
  box-shadow: 0 1px 0 0 var(--fd-colors-border-subtle) inset;
`;
